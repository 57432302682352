import React from 'react';
import classNames from 'classnames';

export default function Item({ onSelect, item }) {
  const { name, icon: Icon, danger, disabled, sort = { has: false, asc: true } } = item;
  const classes = classNames({
    [`menu-item`]: true,
    [`menu-item-danger`]: danger,
    [`menu-item-disabled`]: disabled,
    [`menu-item-desc`]: sort.has && !sort.asc,
  });

  return (
    <div className={classes} onClick={disabled ? null : () => onSelect(item)}>
      <div className='menu-item-holder'>
        <span>{Icon && <Icon className='icon' />}</span>
        {name}

        {sort.has && <span className='rows' />}
      </div>
    </div>
  );
}
