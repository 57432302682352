import './index.less';
import Item from './Item';

const UserList = ({ users = [], renderAction }) => {
  return (
    <ul className='user-list'>
      {users.map((user, key) => (
        <Item key={key} {...{ user, renderAction }} />
      ))}
    </ul>
  );
};

export default UserList;
