import React, { useEffect, useState, ImgHTMLAttributes, ForwardedRef } from "react";
import imgPlaceholder from "images/imgPlaceholder.png";

import { convertImageToBase64, cacheImage, getCachedImage } from "utils/cachedImage";

interface CachedImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  url?: string;
}

const CachedImage = React.forwardRef<HTMLImageElement, CachedImageProps>(
  ({ url, alt, ...props }, ref: ForwardedRef<HTMLImageElement>) => {
    const [src, setSrc] = useState<string | null>(null);

    useEffect(() => {
      const loadImage = async () => {
        if (!url) {
          setSrc(imgPlaceholder);
          return;
        }

        const cachedImage = await getCachedImage(url);

        if (cachedImage) {
          setSrc(cachedImage);
        } else {
          const base64Image = await convertImageToBase64(url);
          await cacheImage(url, base64Image);
          setSrc(base64Image);
        }
      };

      loadImage();
    }, [url]);

    const onError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
      currentTarget.onerror = null;
      currentTarget.src = imgPlaceholder;
    };

    return <img ref={ref} onError={onError} src={src || url} alt={alt} {...props} />;
  }
);

export default CachedImage;
