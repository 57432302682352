import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'types/user';

export type CameraStream = {
  stream: MediaStream;
  userInfo: Pick<IUser, '_id' | 'name'>;
};

type CameraStreams = {
  [key: string]: CameraStream;
};

const initialState: CameraStreams = {};

export const cameraStreamsSlice = createSlice({
  name: 'cameraStreams',
  initialState,
  reducers: {
    addCameraStream(
      state,
      {
        payload: { ancestorId, stream, userInfo },
      }: PayloadAction<{ ancestorId: string; stream: MediaStream; userInfo: Pick<IUser, '_id' | 'name'> }>
    ) {
      if (!state[ancestorId]) {
        state[ancestorId] = { stream, userInfo };
      }
    },
    deleteCameraStream(state, { payload }: PayloadAction<string>) {
      if (state[payload]) {
        state[payload].stream.getTracks().forEach(track => track.stop());
        delete state[payload];
      }
    },
    replaceStream(state, { payload }: PayloadAction<{ producerId: string, stream: MediaStream }>) {
      if (state[payload.producerId]) {
        state[payload.producerId].stream.getTracks().forEach(track => track.stop());
        state[payload.producerId].stream = payload.stream;
      }
    }
  },
});

export const { replaceStream, addCameraStream, deleteCameraStream } = cameraStreamsSlice.actions;

export default cameraStreamsSlice.reducer;
