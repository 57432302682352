import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState: any[] = [];

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    openContextMenu: (state, { payload }) => [...payload],
  },
});

export const { openContextMenu } = contextMenuSlice.actions;

export default contextMenuSlice.reducer;

const selectContextMenu = (state: RootState) => state.contextMenu;
export const getContextMenu = createSelector(selectContextMenu, (state) => state);
