import { createSlice } from '@reduxjs/toolkit';
import { devicesState } from 'types/devices';

const initialState: devicesState = {
  audioDevices: [],
  selectedAudioDevice: null,
  videoDevices: [],
  selectedVideoDevice: null,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setAudioDevices(state, { payload }) {
      state.audioDevices = payload;
      state.selectedAudioDevice = payload[0];
    },
    selectAudioDevice(state, { payload }) {
      state.selectedAudioDevice = payload;
    },
    setVideoDevices(state, { payload }) {
      state.videoDevices = payload;
      state.selectedVideoDevice = payload[0];
    },
    selectVideoDevice(state, { payload }) {
      state.selectedVideoDevice = payload;
    },
  },
});

export const { setAudioDevices, selectAudioDevice, setVideoDevices, selectVideoDevice } = devicesSlice.actions;

export default devicesSlice.reducer;
