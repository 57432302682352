import DialogsService from 'services/dialogs';
import ContextMenuService from 'services/contextMenu';

import DialogsLibrary from 'views/Dialogs';
import ContextMenuLibrary from 'views/ContextMenu';

import { openContextMenu } from 'reducers/contextMenu';
import { openDialog } from 'reducers/dialogs';

import store from 'store';

export type Services = {
  dialogs: DialogsService;
  contextMenu: ContextMenuService;
};

const dialogsDispatcher = (props: any) => store.dispatch(openDialog(props));
const contextMenuDispatcher = (props: any) => store.dispatch(openContextMenu(props));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dialogs: new DialogsService(dialogsDispatcher, DialogsLibrary),
  contextMenu: new ContextMenuService(contextMenuDispatcher, ContextMenuLibrary),
};
