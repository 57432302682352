import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { roomState, Tab, TabPresentation } from 'types/room';

const initialState: roomState = {
  isChatAvailable: true,
  activeTabId: null,
  tabs: {},
  isRecording: false,
  startRecordTime: null,
  isRecordLoading: false,
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setData(state, { payload }: PayloadAction<roomState>) {
      const { isChatAvailable, activeTabId, tabs, isRecording, startRecordTime, isRecordLoading } = payload;

      state.isChatAvailable = isChatAvailable;
      state.activeTabId = activeTabId;
      state.tabs = tabs;
      state.isRecording = isRecording;
      state.startRecordTime = startRecordTime;
      state.isRecordLoading = isRecordLoading;
    },
    toggleChatMode(state, { payload }: PayloadAction<roomState['isChatAvailable']>) {
      state.isChatAvailable = payload;
    },
    toggleRecording(state, { payload: { startRecordTime, isRecording } }: PayloadAction<{ startRecordTime: string | null; isRecording: boolean }>) {
      state.isRecording = isRecording;
      state.startRecordTime = startRecordTime;
      state.isRecordLoading = false;
    },
    toggleRecordLoading(state) {
      state.isRecordLoading = true;
    },
    setActiveTab(state, { payload }: PayloadAction<roomState['activeTabId']>) {
      state.activeTabId = payload;
    },
    addTabs(state, { payload }: PayloadAction<{ tabs: roomState['tabs']; activeTabId: roomState['activeTabId'] }>) {
      state.tabs = { ...state.tabs, ...payload.tabs };
      state.activeTabId = payload.activeTabId;
    },
    deleteTab(state, { payload }: PayloadAction<{ tabId: Tab['id']; activeTabId: roomState['activeTabId'] }>) {
      if (state.tabs[payload.tabId]) {
        delete state.tabs[payload.tabId];
      }

      if (state.activeTabId !== payload.activeTabId) {
        state.activeTabId = payload.activeTabId;
      }
    },
    changeSlide(state, { payload }: PayloadAction<{ tabId: Tab['id']; slideNumber: number }>) {
      const isPres = (obj: any): obj is TabPresentation => !!obj && ['presentation', 'image'].includes(obj.type);

      if (state.tabs[payload.tabId] && isPres(state.tabs[payload.tabId])) {
        (state.tabs[payload.tabId] as TabPresentation).material.currentSlide = payload.slideNumber;
      }
    },
  },
});

export const { toggleRecordLoading, toggleRecording, setData, toggleChatMode, setActiveTab, addTabs, deleteTab, changeSlide } =
  roomSlice.actions;

export default roomSlice.reducer;
