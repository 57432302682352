import { IUser } from './user';

export type fileState = {
  files: IFile[];
  currentType: keyof typeof FileType;
  isLoading: boolean;
  perPage: number;
  page: number;
  sort: string;
  direct: boolean;
  search: string;
  totalCount: number;
  totalSize: number;
  filesByType: {
    [key in keyof typeof FileType]: { size: number; count: number };
  };
};

export enum FileType {
  video = 'video',
  presentation = 'presentation',
  image = 'image',
  audio = 'audio',
  // text = 'text',
  // youtube = 'youtube',
}

export interface IFile {
  _id: string;
  name: string;
  type: keyof typeof FileType;
  size: number;
  path: string;
  previewUrls: {
    small: string;
    thumb: string;
  };
  slides?: string[];
  slidesPreview?: string[];
  duration?: number | null;
  owner: IUser | IUser['_id'];
  createdAt?: string;
  updatedAt?: string;
}
