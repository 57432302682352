import { Dispatch, GetState } from 'store';
import { IUser, Role } from 'types/user';

export const checkAvailable = (requiredRole: Role) => (dispatch: Dispatch, getState: GetState) => {
  const { user } = getState().user;
  if (!user) return false;

  return user.role >= requiredRole;
};

export const checkRightsRegardRoster = (peer: IUser) => (dispatch: Dispatch, getState: GetState) => {
  const { user } = getState().user;
  if (!user) return false;

  return user.role >= peer.role;
};
