import Default from './default';
import Create from './create';

const contextMenu = {
  default: Default,
  create: Create,
} as const;

export type ContextMenuLibrary = typeof contextMenu;
export default contextMenu;
