import React, { memo, useState } from 'react';
import TextField from '@mui/material/TextField';

type Props = {
  className?: string;
  onSearch: (value: string) => { payload: any; type: string };
};

const FileSearch: React.FC<Props> = memo(({ className, onSearch }) => {
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [value, setValue] = useState<string>('');

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);

    if (searchTimeout) clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout((value) => onSearch(value), 500, target.value));
  };

  return (
    <TextField
      className={className}
      size="small"
      label="Название"
      placeholder="Начните вводить название..."
      value={value}
      onChange={onChange}
    />
  );
});

export default FileSearch;
