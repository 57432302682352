const num = (value: number, postfix?: string) => {
  if (value === 0) return '';
  if (postfix) return value + ' ' + postfix;

  return value;
};

const calculateTime = (units: number, separator?: string) => {
  if (units === 0 && separator) return 0;
  if (units === 0 && !separator) return '0 сек';

  const hours = Math.floor((units / 3600) % 24);
  const minutes = Math.floor((units / 60) % 60);
  const seconds = Math.floor(units % 60);

  if (separator) return `${num(hours, separator)} ${num(minutes, separator)} ${num(seconds)}`;

  return `${num(hours, 'ч')} ${num(minutes, 'мин')} ${num(seconds, 'сек')}`;
};

export default calculateTime;
