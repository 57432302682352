import React from 'react';
import classNames from 'classnames';

import Item from '../default/item';

export default class Default extends React.Component {
  render() {
    const { left, top, items, onSelect } = this.props;
    const classes = classNames({
      [`kApp-menubox`]: true,
      [`turn-top`]: true,
      [`menu-create`]: true,
      menu: true,
    });

    return (
      <div className={classes} style={{ left, top }}>
        <div className='menu-inner'>
          {items.map((item, i) => (
            <Item key={i} item={item} onSelect={onSelect} />
          ))}
        </div>
      </div>
    );
  }
}
