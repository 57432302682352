import { DialogsLibrary } from 'views/Dialogs';

type DialogsDispatcher = (payload: any) => void;

export default class DialogsService {
  _dispatcher: DialogsDispatcher | null = null;
  _library: DialogsLibrary | null = null;
  _dialogs: { name: string | null; component: any; options: { [key: string]: any } }[] = [];

  constructor(dispatcher: DialogsDispatcher, library: DialogsLibrary) {
    this._dispatcher = dispatcher;
    this._library = library;
  }

  open(name: keyof DialogsLibrary, dirtyOptions?: object) {
    if (this._library === null) return;

    const options = this._clear(dirtyOptions);
    if (typeof name === `string`) {
      if (!this._library[name]) throw Error(`Dialog component ${name} is not exists!`);

      return this._register(name, this._library[name], options);
    }

    return this._register(null, Object.assign({}, this._clear(name), options));
  }

  close(dialog: number | keyof DialogsLibrary) {
    if (typeof dialog === 'number') {
      this._dialogs.splice(dialog, 1);
      return this._dispatch();
    }
    console.log(dialog, this._dialogs)
    this._dialogs = this._dialogs.filter(({ name }) => name !== dialog);
    return this._dispatch();
  }

  closeLast() {
    return this.close(this._dialogs.length - 1);
  }

  closeAll() {
    this._dialogs = [];
    this._dispatch();
  }

  isOpen() {
    return !!this._dialogs.length;
  }

  _clear(dirtyOptions = {}) {
    return Object.keys(dirtyOptions)
      .filter((x) => typeof dirtyOptions[x as keyof object] !== `undefined`)
      .reduce((res, x) => {
        res[x as keyof object] = dirtyOptions[x as keyof object];

        return res;
      }, {});
  }

  _register(name: keyof DialogsLibrary | null, component: object, options = {}) {
    const idx = this._dialogs.push({ name, component, options });
    this._dispatch();

    return idx - 1;
  }

  _dispatch() {
    if (!this._dispatcher) return;

    this._dispatcher({ dialogs: this._dialogs });
  }
}
