import './index.less';
import LinearProgress from '@mui/material/LinearProgress';

import ImageIcon from '@mui/icons-material/Image';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import calculateSpace from 'utils/calculateSpace';
import cropText from 'utils/cropText';
import { UploaderFile } from 'store/reducers/uploader';

const icons = {
  video: <VideoFileIcon sx={{ justifySelf: 'center' }} />,
  audio: <AudioFileIcon sx={{ justifySelf: 'center' }} />,
  presentation: <PictureAsPdfIcon sx={{ justifySelf: 'center' }} />,
  image: <ImageIcon sx={{ justifySelf: 'center' }} />,
} as const;

const UploadFile: React.FC<UploaderFile> = ({ type, name, size, error, progress }) => {
  return (
    <section className="uploader-file">
      {icons[type]}
      <div title={name} className="uploader-file__info">
        <p className="uploader-file__name">{cropText(name)}</p>
        <p className="uploader-file__size">{calculateSpace(size)}</p>
      </div>
      <div className="upload-file__bar">
        <LinearProgress
          variant="determinate"
          color={error ? 'error' : progress === 100 ? 'success' : 'primary'}
          value={progress}
        />
      </div>
      {error && <div className="upload-file__error">Ошибка загрузки или обработки файла</div>}
    </section>
  );
};

export default UploadFile;
