import { WebinarStatus } from 'types/webinar';
import { API_URL } from './config';

export const mediaType = {
  audio: 'audioType',
  video: 'videoType',
  screen: 'screenType',
};

export const webinarStatus: { [key in WebinarStatus]: WebinarStatus } = {
  online: 'online',
  past: 'past',
  future: 'future',
};

export const chartBgColors: React.CSSProperties['color'][] = [
  'rgba(249, 65, 68, 0.5)',
  'rgba(249, 199, 79, 0.5)',
  'rgba(39, 125, 161, 0.5)',
  'rgba(243, 114, 44, 0.5)',
  'rgba(87, 117, 144, 0.5)',
  'rgba(248, 150, 30, 0.5)',
  'rgba(249, 132, 74, 0.5)',
  'rgba(144, 190, 109, 0.5)',
  'rgba(67, 170, 139, 0.5)',
  'rgba(77, 144, 142, 0.5)',
];

export const chartBorderColors: React.CSSProperties['color'][] = [
  'rgb(249, 65, 68)',
  'rgb(249, 199, 79)',
  'rgb(39, 125, 161)',
  'rgb(243, 114, 44)',
  'rgb(87, 117, 144)',
  'rgb(248, 150, 30)',
  'rgb(249, 132, 74)',
  'rgb(144, 190, 109)',
  'rgb(67, 170, 139)',
  'rgb(77, 144, 142)',
];

export const API = {
  EMAIL_CONFIRM: `${API_URL}/email-confirm`,

  // webinar
  WEBINARS: `${API_URL}/webinars`,
  WEBINAR_STATISTIC: `${API_URL}/statistics`,
  WEBINAR_LANDING: `${API_URL}/webinars/landing`,
  WEBINAR_CREATE: `${API_URL}/webinars/create`,

  // user
  USERS: `${API_URL}/users`,
  ME: `${API_URL}/users/me`,
  PASS_RECOVERY: `${API_URL}/password-recovery`,

  // auth
  REFRESH: `${API_URL}/refresh`,
  SIGNIN: `${API_URL}/sign-in`,
  SIGNOUT: `${API_URL}/sign-out`,
  SIGNUP: `${API_URL}/sign-up`,
  SIGNUP_VIEWER: `${API_URL}/sign-up/viewer`,

  // ADMIN

  ADMIN: `${API_URL}/admin`,
};
