import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import { addUploadFile, changeUploadFile, setErrorToFile } from 'reducers/uploader';
import FileService from 'services/FileService';
import { routeNames } from 'routes/routeNames';
import $api from 'utils/MainApi';
import { FileType, IFile } from 'types/file';

type fetchData = {
  currentType: string;
  sort?: string;
  direct?: boolean;
  page?: number;
  perPage?: number;
  search?: string;
};

export const fetchFiles = createAsyncThunk('file/fetchFiles', async (data: fetchData, { rejectWithValue }) => {
  try {
    const response = await FileService.getFiles(data);
    return response.data;
  } catch (err: any) {
    let error = err;
    if (!error.response) throw err;

    return rejectWithValue(error.response.data);
  }
});

export const getFileStatistic = createAsyncThunk('file/getFileStatistic', async () => {
  try {
    const response = await FileService.getFileStatistic();
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const uploadFile = createAsyncThunk(
  'file/uploadFile',
  async ({ file, currentType }: { file: File; currentType: keyof typeof FileType }, thunkApi) => {
    const uploadedFile = {
      type: currentType,
      name: file.name,
      progress: 0,
      size: file.size,
      _id: uuidv4(),
      error: false,
    };

    thunkApi.dispatch(addUploadFile(uploadedFile));
    const formData = new FormData();
    formData.append(currentType, file);
    try {
      const response = await $api.post(`${routeNames.USER_FILES_PAGE}/upload/${currentType}`, formData, {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength) {
            let progress = Math.round((progressEvent.loaded * 100) / totalLength);
            thunkApi.dispatch(changeUploadFile({ ...uploadedFile, progress }));
          }
        },
      });

      return response.data;
    } catch (err: any) {
      thunkApi.dispatch(setErrorToFile({ _id: uploadedFile._id, error: true }));
      if (err.response?.status >= 500) {
        return thunkApi.rejectWithValue({
          message: `Внутренняя ошибка сервера. Код: ${err.response.status}`,
          status: err.response.status,
        });
      }
      if (err.response?.status >= 400) {
        return thunkApi.rejectWithValue(err.response.data);
      }
      if (err.message === 'Network Error') {
        return thunkApi.rejectWithValue({
          message: 'Нестабильное подключение к интернету',
          status: null,
        });
      }
    }
  }
);

export const downloadFile = createAsyncThunk('file/downloadFile', async (file: IFile) => {
  try {
    const response = await FileService.downloadFile(file);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    toast.error('Ошибка скачивания');
  }
});

export const deleteFile = createAsyncThunk('file/deleteFile', async (fileId: IFile['_id'], thunkApi) => {
  try {
    const response = await FileService.deleteFile(fileId);
    return response.data;
  } catch (err: any) {
    if (err.response?.status >= 500) {
      return thunkApi.rejectWithValue({
        message: `Внутренняя ошибка сервера. Код: ${err.response.status}`,
        status: err.response.status,
      });
    }
    if (err.response?.status >= 400) {
      return thunkApi.rejectWithValue(err.response.data);
    }
    if (err.message === 'Network Error') {
      return thunkApi.rejectWithValue({
        message: 'Нестабильное подключение к интернету',
        status: null,
      });
    }
  }
});

export const downloadRecord = createAsyncThunk(
  'record/download',
  async ({ recordId, name }: { recordId: string; name: string }) => {
    try {
      const response = await FileService.downloadRecord(recordId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.webm`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      toast.error('Ошибка скачивания');
    }
  }
);
