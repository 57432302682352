import './index.less';
import { memo } from 'react';

import CloseIcon from '@mui/icons-material/Close';

const Panel = memo(({ stopPropagation, close }) => {
  return (
    <ul onClick={stopPropagation} className="dialog-image-view__actions">
      <li onClick={close}>
        <CloseIcon />
      </li>
    </ul>
  );
});

export default Panel;
