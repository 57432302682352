import Chip, { ChipProps } from '@mui/material/Chip';
import { IUser, Role, roleLabels } from 'types/user';

const colors: Record<Role, ChipProps['color']> = {
  [Role.VIEWER]: 'default',
  [Role.MEMBER]: 'default',
  [Role.MODERATOR]: 'warning',
  [Role.ADMIN]: 'error',
};

type Props = {
  user: IUser;
  size?: ChipProps['size'];
};

const RoleTag: React.FC<Props> = ({ user: { role = Role.VIEWER } = {}, size }) => {
  return <Chip color={colors[role]} label={roleLabels[role]} size={size} />;
};

export default RoleTag;
