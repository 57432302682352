import FileSelector from './fileSelector';
import RoomMaterials from './roomMaterials';
import RoomSettings from './roomSettings';
import RoomRosters from './roomRosters';
import RoomHands from './roomHands';
import Сonfirm from './confirm';
import RoomBroadcastInvite from './roomBroadcastInvite';
import ImageView from './imageView';
import VideoView from './videoView';
import Alert from './alert';

const dialogs = {
  fileSelector: FileSelector,
  roomMaterials: RoomMaterials,
  roomSettings: RoomSettings,
  roomRosters: RoomRosters,
  confirm: Сonfirm,
  roomHands: RoomHands,
  roomBroadcastInvite: RoomBroadcastInvite,
  imageView: ImageView,
  videoView: VideoView,
  alert: Alert,
} as const;

export type DialogsLibrary = typeof dialogs;
export default dialogs;
