import { useState } from 'react';

import Success from './Success';
import Cancel from './Cancel';
import { DialogProps } from './Dialog';

const Footer: React.FC<DialogProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const empty = async () => undefined;

  const {
    disableAction = false,
    Footer: FooterComp,
    successButton,
    cancelButton,
    success = empty,
    cancel = empty,
    close,
  } = props;

  if (typeof FooterComp === 'boolean' && !FooterComp) return null;
  if (typeof FooterComp === `function` || typeof FooterComp === `object`) return <FooterComp {...props} />;

  const onSuccess = () => {
    setIsLoading(true);

    return Promise.resolve(success()).then(() => {
      setIsLoading(false);
      close();
    });
  };

  const onCancel = () => Promise.resolve(cancel()).then(() => close());

  return (
    <div className="dialog__footer">
      <div className="dialog__footer-btns">
        {!disableAction && <Success {...{ successButton, isLoading, onSuccess }} />}
        <Cancel {...{ cancelButton, isLoading, onCancel }} />
      </div>
    </div>
  );
};

export default Footer;
