import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { IPeer, IWebinar, webinarState } from 'types/webinar';
import { IUser } from 'types/user';
import { webinarApi } from 'store/actions/webinar';

const defaultDraft: webinarState['draft'] = {
  name: '',
  announcement: '',
  description: '',
  duration: 5,
  cover: null,
  level: 'based',
  tags: [],
  speaker: null,
  startDate: new Date(),
};

const initialState: webinarState = {
  webinar: null,
  draft: defaultDraft,
  peers: {},
};

export const webinarSlice = createSlice({
  name: 'webinar',
  initialState,
  reducers: {
    resetDraft(state) {
      state.draft = defaultDraft;
    },
    blockUser(state, { payload }: PayloadAction<IUser['_id']>) {
      state.webinar!.blackList.push(payload);
    },
    unblockUser(state, { payload }: PayloadAction<IUser['_id']>) {
      state.webinar!.blackList = state.webinar!.blackList.filter((userId) => userId !== payload);
    },
    setPeers(state, { payload }: PayloadAction<{ [key: IPeer['id']]: IPeer }>) {
      state.peers = payload;
    },
    addPeer(state, { payload }: PayloadAction<IPeer>) {
      if (!state.peers[payload.id]) state.peers[payload.id] = payload;
    },
    deletePeer(state, { payload }: PayloadAction<IPeer['id']>) {
      if (state.peers[payload]) delete state.peers[payload];
    },
    setPeerHand(state, { payload: { userId, isHandUp } }: PayloadAction<{ userId: IPeer['id']; isHandUp: boolean }>) {
      if (state.peers[userId]) state.peers[userId].isHandUp = isHandUp;
    },
    lowerHands(state) {
      for (const key in state.peers) {
        state.peers[key].isHandUp = false;
      }
    },
    allowBroadcast(state, { payload }: PayloadAction<IPeer['id']>) {
      if (state.peers[payload]) state.peers[payload].isBroadcastAllowed = true;
    },
    forbidBroadcast(state, { payload }: PayloadAction<IPeer['id']>) {
      if (state.peers[payload]) state.peers[payload].isBroadcastAllowed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      webinarApi.endpoints.getWebinar.matchFulfilled,
      (state, { payload }: PayloadAction<IWebinar>) => {
        const { name, announcement, description, duration, cover, level, tags, speaker, startDate } = payload;
        state.webinar = payload;
        state.draft = { name, announcement, description, duration, cover, level, tags, speaker, startDate };
      }
    );
  },
});

export const {
  resetDraft,
  blockUser,
  unblockUser,
  setPeers,
  addPeer,
  deletePeer,
  setPeerHand,
  lowerHands,
  allowBroadcast,
  forbidBroadcast,
} = webinarSlice.actions;

export default webinarSlice.reducer;

// selectors

const webinar = (state: RootState) => state.webinar;

export const selectWebinar = createSelector(webinar, ({ webinar }) => webinar);
export const selectDraft = createSelector(webinar, ({ draft }) => draft);

export const getBlackList = createSelector(selectWebinar, (data) =>
  data && 'blackList' in data ? data.blackList : []
);

export const getPeersHandsUp = createSelector(webinar, ({ peers }) =>
  [...Object.values(peers)].filter(({ isHandUp }) => isHandUp)
);
