import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, userState } from 'types/user';

import { IMessage } from 'types/webinar';
import { RootState } from '../index';
import { CheckAuthData, userApi } from 'store/actions/user';

const defaultDraft = {
  name: '',
  about: '',
  email: '',
  city: '',
  country: '',
  socialLinks: [],
  avatar: null,
};

const initialState: userState = {
  user: null,
  draft: defaultDraft,
  errorData: null,
  isAuth: false,
  isLoaded: false,
  replyMessage: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setReplyMessage(state, { payload }: PayloadAction<IMessage>) {
      state.replyMessage = payload;
    },
    clearReplyMessage(state) {
      state.replyMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.editProfile.matchFulfilled, (state, { payload }: PayloadAction<IUser>) => {
      const { name, about, avatar, email, city, country, socialLinks } = payload;
      state.user = payload;
      state.draft = { name, about, avatar, email, city, country, socialLinks };
    });

    // auth
    builder.addMatcher(userApi.endpoints.checkAuth.matchPending, (state) => {
      state.isLoaded = false;
    });
    builder.addMatcher(
      userApi.endpoints.checkAuth.matchFulfilled,
      (state, { payload }: PayloadAction<CheckAuthData>) => {
        const { name, about, avatar, email, city, country, socialLinks } = payload.user;
        state.user = payload.user;
        state.draft = { name, about, avatar, email, city, country, socialLinks };
        state.isAuth = true;
        state.isLoaded = true;
      }
    );
    builder.addMatcher(userApi.endpoints.checkAuth.matchRejected, (state) => {
      state.isLoaded = true;
    });
    builder.addMatcher(userApi.endpoints.signIn.matchPending, (state) => {
      state.isLoaded = false;
    });
    builder.addMatcher(userApi.endpoints.signIn.matchFulfilled, (state, { payload }: PayloadAction<CheckAuthData>) => {
      const { name, about, avatar, email, city, country, socialLinks } = payload.user;
      state.user = payload.user;
      state.draft = { name, about, avatar, email, city, country, socialLinks };
      state.isAuth = true;
      state.isLoaded = true;
    });
    builder.addMatcher(userApi.endpoints.signIn.matchRejected, (state) => {
      state.isLoaded = true;
    });
    builder.addMatcher(userApi.endpoints.signOut.matchFulfilled, (state) => {
      state.user = null;
      state.draft = defaultDraft;
      state.isAuth = false;
    });
    builder.addMatcher(userApi.endpoints.signUp.matchFulfilled, (state, { payload }: PayloadAction<CheckAuthData>) => {
      const { name, about, avatar, email, city, country, socialLinks } = payload.user;
      state.user = payload.user;
      state.draft = { name, about, avatar, email, city, country, socialLinks };
      state.isAuth = true;
    });
    builder.addMatcher(
      userApi.endpoints.signUpViewer.matchFulfilled,
      (state, { payload }: PayloadAction<CheckAuthData>) => {
        const { name, about, avatar, email, city, country, socialLinks } = payload.user;
        state.user = payload.user;
        state.draft = { name, about, avatar, email, city, country, socialLinks };
        state.isAuth = true;
      }
    );
  },
});

export const { clearReplyMessage, setReplyMessage } = userSlice.actions;

export default userSlice.reducer;

// selectors

const user = (state: RootState) => state.user;

export const selectUser = createSelector(user, ({ user }) => user);
export const selectDraft = createSelector(user, ({ draft }) => draft);
export const selectReplyMessage = createSelector(user, ({ replyMessage }) => replyMessage);
