import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import FastForwardRounded from '@mui/icons-material/FastForward';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTypedDispatch, useTypedSelector } from 'store/index';
import { setIsPlaying } from 'reducers/audioPlayer';

const mainIconColor = '#fff';
type Props = { onPrevClick: () => void; onNextClick: () => void };

const Controls: React.FC<Props> = ({ onPrevClick, onNextClick }) => {
  const dispatch = useTypedDispatch();
  const matches = useMediaQuery('(max-width:620px)');
  const { isPlaying, tracks } = useTypedSelector((state) => state.audioPlayer);

  const toggleStatus = () => dispatch(setIsPlaying(!isPlaying));

  const isInvisible = tracks.length < 2 || matches;

  return (
    <Stack direction="row">
      {!isInvisible && (
        <IconButton onClick={onPrevClick}>
          <FastRewindRounded htmlColor={mainIconColor} />
        </IconButton>
      )}
      <IconButton onClick={toggleStatus}>
        {isPlaying ? (
          <PauseRounded fontSize="large" htmlColor={mainIconColor} />
        ) : (
          <PlayArrowRounded fontSize="large" htmlColor={mainIconColor} />
        )}
      </IconButton>
      {!isInvisible && (
        <IconButton onClick={onNextClick}>
          <FastForwardRounded htmlColor={mainIconColor} />
        </IconButton>
      )}
    </Stack>
  );
};

export default Controls;
