import './index.less';
import { memo } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Arrows = memo(({ toPrevImage, toNextImage }) => {
  return (
    <>
      <div onClick={toPrevImage} className="dialog-image-view__arrow dialog-image-view__arrow_prev">
        <ArrowBackIosNewIcon sx={{ fontSize: 18 }} />
      </div>
      <div onClick={toNextImage} className="dialog-image-view__arrow dialog-image-view__arrow_next">
        <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
      </div>
    </>
  );
});

export default Arrows;
