import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoader {
  invisible?: boolean;
}

const Loader: React.FC<ILoader> = ({ invisible = false }) => {
  return (
    <Backdrop invisible={invisible} open={true} sx={{ zIndex: 99999999 }}>
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default Loader;
