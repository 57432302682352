import './index.less';
import React from 'react';

import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type ResultType = 'error' | 'warning' | 'primary' | 'default' | 'success';
type Props = {
  type?: ResultType;
  textOnly?: boolean;
  color?: SvgIconProps['color'];
  children?: React.ReactNode;
  maxWidth?: React.CSSProperties['maxWidth'];
  title?: string;
  subTitle?: string;
};

const iconList: { [key: string]: (props: SvgIconProps) => JSX.Element } = {
  error: HighlightOffIcon,
  warning: WarningAmberIcon,
  success: CheckCircleOutlineIcon,
  primary: ErrorOutlineIcon,
  default: MailOutlineIcon,
};

const renderIcon = (color: SvgIconProps['color'], type: ResultType) => {
  const Icon = iconList[type];

  return <Icon color={color} sx={{ fontSize: 72 }} />;
};

const Result: React.FC<Props> = ({
  color,
  type = 'default',
  textOnly = false,
  children,
  maxWidth,
  title,
  subTitle,
}) => {
  return (
    <Stack maxWidth={maxWidth} alignItems="center" justifyContent="center" className="result">
      {!textOnly && renderIcon(color, type)}
      {title && <p className="result__title">{title}</p>}
      {subTitle && <p className="result__subtitle">{subTitle}</p>}
      {children}
    </Stack>
  );
};

export default Result;
