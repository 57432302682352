import './index.less';

import cn from 'classnames';
import { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import useMediaQuery from '@mui/material/useMediaQuery';

import PopupItems, { optionsPresets } from './Item';
import EmptyBox from 'components/EmptyBox';
import emptyPic from 'images/empty/delayed.svg';

import { declOfNum } from 'utils/index';
import validateFile from 'utils/validateFile';
import { uploadFile, fetchFiles, getFileStatistic } from 'store/actions/file';
import { setPage } from 'store/reducers/file';
import { clear } from 'store/reducers/popupFileSelector';
import { useTypedDispatch, useTypedSelector } from 'store';
import { FileType, IFile } from 'types/file';

type Props = {
  success?: (selectedFiles: IFile[]) => void;
  closeAll: () => void;
  isNeedFetch?: boolean;
  maxSelected?: number;
  fileType?: keyof typeof FileType;
};

const Body: React.FC<Props> = ({ success, isNeedFetch = false, maxSelected = 1, closeAll, fileType }) => {
  const dispatch = useTypedDispatch();
  const matches = useMediaQuery('(max-width:520px)');

  const { files, page, perPage, filesByType, currentType } = useTypedSelector((state) => state.file);
  const { selectedFiles } = useTypedSelector((state) => state.popupFileSelector);

  const type = fileType ?? currentType;
  const pages = Math.ceil(filesByType[type].count / perPage) ?? 1;

  const FileItem = PopupItems[type];
  const { accept, text, allowedExtensions, allowedTypes, declinationArray } = optionsPresets[type];

  useEffect(() => {
    dispatch(clear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNeedFetch) dispatch(getFileStatistic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedFetch]);

  useEffect(() => {
    if (isNeedFetch) dispatch(fetchFiles({ currentType: type, page, perPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isNeedFetch, page]);

  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    console.log('roomMaterials page =', page);
    dispatch(setPage(page));
  };

  const fileUploadHandler = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.files) return;

    const files = [...Object.values(target.files)];

    files.forEach((file) => {
      const isValid = validateFile({ allowedExtensions, allowedTypes, file });
      if (isValid) return dispatch(uploadFile({ file, currentType: type }));
    });
  };

  const renderActionButton = () => {
    if (!success) return;

    const onAction = () => Promise.resolve(success(selectedFiles)).then(() => closeAll());
    const numberOfFiles = selectedFiles.length;
    const btnLabel = !!numberOfFiles
      ? `Прикрепить ${numberOfFiles > 1 ? numberOfFiles : ''} ${declOfNum(numberOfFiles, declinationArray)}`
      : `Выберите ${declinationArray[0]}`;

    return (
      <Button disabled={!numberOfFiles} onClick={onAction} variant="contained" disableElevation>
        {btnLabel}
      </Button>
    );
  };

  return (
    <div className="dialog-file-selector">
      <div className="dialog-file-selector__upload-buttons">
        <Button startIcon={<UploadIcon />} variant="outlined" component="label" disableElevation fullWidth>
          {text}
          <input onChange={fileUploadHandler} hidden accept={accept} multiple type="file" />
        </Button>
      </div>
      {!!files.length ? (
        <>
          <div className="dialog-file-selector__content">
            <TransitionGroup
              className={cn('dialog-file-selector__content-inner', {
                'dialog-file-selector__content-inner-audio': type === 'audio',
              })}
            >
              {files.map((item) => (
                <CSSTransition key={item._id} timeout={300} classNames="dialog-file-selector__content-inner">
                  <FileItem isAction={!!success} {...{ item, currentType: type, maxSelected }} />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
          <div className="dialog-file-selector__actions">
            {pages > 1 && (
              <Pagination
                sx={{ mr: matches ? 'unset' : 'auto' }}
                page={page}
                onChange={handlePagination}
                count={pages}
                color="primary"
              />
            )}
            {renderActionButton()}
          </div>
        </>
      ) : (
        <EmptyBox severity="info" image={emptyPic} text="У вас ещё нет загруженных файлов" />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'fileSelector',
  Footer: false,
  Body,
};
