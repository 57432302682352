import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Alert from '@mui/material/Alert';

import UserList from 'components/UserList';

import { checkAvailable, checkRightsRegardRoster } from 'actions/app';

import { openBlockedUsersContextMenu } from 'actions/contextMenu';
import { useTypedDispatch, useTypedSelector } from 'store';
import { IUser, Role } from 'types/user';
import { useGetBlackListQuery } from 'store/actions/webinar';
import Loader from 'components/Loader';

const BlockedTab = () => {
  const dispatch = useTypedDispatch();
  const { webinar } = useTypedSelector((state) => state.webinar) || {};
  const { data: blackList = [], isLoading } = useGetBlackListQuery(webinar ? webinar._id : '');

  const onClickCtrl = ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: IUser['_id']) => {
    const { left, top } = currentTarget.getBoundingClientRect();

    const targetSizes = {
      clientTargetWidth: currentTarget.clientWidth,
      clientTargetHeight: currentTarget.clientHeight,
    };
    dispatch(openBlockedUsersContextMenu(userId, { left, top, targetSizes }));
  };

  const renderContextMenu = (peer: IUser) => {
    const isAvailable = dispatch(checkAvailable(Role.MODERATOR));
    const isUserImportantRoster = dispatch(checkRightsRegardRoster(peer));

    if (isAvailable && isUserImportantRoster) {
      return (
        <div className="user-list__context-menu">
          <IconButton onClick={(e) => onClickCtrl(e, peer._id)}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      );
    }
  };

  if (isLoading) return <Loader />;

  return !!blackList.length ? (
    <>
      <TextField
        sx={{ mb: 2 }}
        placeholder="Введите имя участника..."
        label="Имя участника"
        size="small"
        variant="outlined"
        fullWidth
      />
      <UserList users={blackList} renderAction={renderContextMenu} />
    </>
  ) : (
    <Alert severity="info">Нет заблокированных участников</Alert>
  );
};

export default BlockedTab;
