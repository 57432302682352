type Props = {
  allowedExtensions: string[];
  allowedTypes: string[];
  file: File;
};

const validateFile = ({ allowedExtensions, allowedTypes, file }: Props) => {
  const extantion = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
  const type = file.type.substring(file.type.lastIndexOf('/') + 1).toLowerCase();

  if (allowedExtensions.indexOf(extantion) === -1 || allowedTypes.indexOf(type) === -1) return false;

  return true;
};

export default validateFile;
