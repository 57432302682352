import './index.less';
import React from 'react';

import { IUser } from 'types/user';
import { sourceWithBaseUrl } from 'utils/index';
import CachedImage from 'components/CachedImage';

type Props = {
  user: IUser;
  size?: number;
  shape?: string;
};

const Avatar: React.FC<Props> = ({ user, size = 40, shape = 'circle' }) => {
  const { avatar, name = '', avatarGradient } = user;

  const style = {
    height: size,
    width: size,
    minHeight: size,
    minWidth: size,
    borderRadius: shape === 'circle' ? '50%' : 5,
  };

  if (avatar) {
    return (
      <CachedImage
        style={style}
        className="avatar"
        url={sourceWithBaseUrl(avatar.previewUrls?.thumb)}
        alt={`Аватар ${name}`}
      />
    );
  }

  if (avatarGradient) {
    return (
      <div
        style={{
          ...style,
          background: `linear-gradient(135deg, ${avatarGradient.first} 10%, ${avatarGradient.second} 100%)`,
        }}
        className="avatar avatar_with-name"
      >
        <span style={{ fontSize: 16 * (size / 40), lineHeight: `${size}px` }} className="avatar__name">
          {name[0].toUpperCase()}
        </span>
      </div>
    );
  }

  return (
    <div style={style} className="avatar avatar_with-name avatar_default">
      <span style={{ fontSize: 16 * (size / 40), lineHeight: `${size}px` }} className="avatar__name">
        {name && name[0].toUpperCase()}
      </span>
    </div>
  );
};

export default Avatar;
