export const routeNames = {
  HOME_PAGE: '/',
  WEBINAR_SCHEDULE_PAGE: '/webinars',
  SUPPORT_PAGE: '/support',
  EMAIL_CONFIRM_PAGE: '/email-confirm/:link',
  WEBINAR_LANDING_PAGE: '/webinars/landing/:webinarId',
  SIGNIN: '/signin',
  PASS_RECOVERY: '/password-recovery',
  SIGNUP: '/signup',
  SIGNUP_VIEWER: '/signup/viewer',
  WEBINAR_PAGE: '/webinar/:webinarId',
  USER_WEBINARS_PAGE: '/my-webinars',
  WEBINAR_CREATION_PAGE: '/create-webinar',
  WEBINAR_EDIT_PAGE: '/edit-webinar/:webinarId',
  WEBINAR_STATISTICS_PAGE: '/statistics/:webinarId',
  PROFILE_PAGE: '/profile',
  RECORDS_PAGE: '/records',
  USER_FILES_PAGE: '/files',
  REFRESH: '/refresh',
  ADMIN: '/admin',
  NON_EXISTENT_PAGE: '*',
};
