import { blockUser, unblockUser } from './webinarRoom';

import { Dispatch, GetState } from 'store';
import { deleteFile } from 'actions/file';
import { setCurrentType } from 'reducers/file';
import { toggleAdminTab } from 'reducers/roomHelpers';

import { asyncEmit } from 'utils/socket';
import { Services } from 'core';

import { fileState, FileType, IFile } from 'types/file';
import { IWebinar } from 'types/webinar';
import { IUser } from 'types/user';
import { Tab } from 'types/room';
import { webinarApi } from './webinar';
import { DialogsLibrary } from 'views/Dialogs';

export const closeDialog = (dialog: number | keyof DialogsLibrary) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.close(dialog);
  };
};

export const closeAllDialogs = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.closeAll();
  };
};

export const openImageView = (source: string | string[] | undefined) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.open('imageView', { source });
  };
};

export const openVideoView = (source: string) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.open('videoView', { source });
  };
};

export const openRoomHands = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dialogs.closeAll();
    return dialogs.open('roomHands');
  };
};

export const openRoomRosters = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dialogs.closeAll();
    return dialogs.open('roomRosters');
  };
};

export const openRoomSettings = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dialogs.closeAll();
    return dialogs.open('roomSettings');
  };
};

export const openRoomMaterials = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dialogs.closeAll();
    return dialogs.open('roomMaterials');
  };
};

export const openRoomBroadcastInvite = ({ defaultIsMicroOnly = false }: { defaultIsMicroOnly?: boolean } = {}) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dialogs.closeAll();
    return dialogs.open('roomBroadcastInvite', { defaultIsMicroOnly });
  };
};

export const fileDeleteConfirm = (fileId: IFile['_id']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const success = async () => await dispatch(deleteFile(fileId));

    return dialogs.open('confirm', {
      Title: 'Удаление',
      message: 'Вы действительно хотите удалить файл?',
      successButton: { danger: true, title: 'Удалить' },
      success,
    });
  };
};

export const webinarDeleteConfirm = (webinarId: IWebinar['_id']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const success = async () => await dispatch(webinarApi.endpoints.deleteWebinar.initiate(webinarId));

    return dialogs.open('confirm', {
      Title: 'Удаление',
      message: 'Вы действительно хотите удалить вебинар?',
      successButton: { danger: true, title: 'Удалить' },
      success,
    });
  };
};

export const userBlockConfirm = (userId: IUser['_id']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const success = async () => await dispatch(blockUser(userId));

    return dialogs.open('confirm', {
      Title: 'Блокировка участника',
      message: 'Вы действительно хотите заблокировать участника мероприятия?',
      successButton: { danger: true, title: 'Заблокировать' },
      success,
    });
  };
};

export const userUnblockConfirm = (userId: IUser['_id']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const success = async () => await dispatch(unblockUser(userId));

    return dialogs.open('confirm', {
      Title: 'Снятие блокировки участника',
      message: 'Вы действительно хотите разблокировать участника мероприятия?',
      successButton: { title: 'Разблокировать' },
      success,
    });
  };
};

// --------------- FILE SELECTOR ---------------

export const openFileSelector = (type?: keyof typeof FileType, success?: (files: IFile[]) => void) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const fileType = type ?? getState().file.currentType;

    const titles: { [key: string]: string } = {
      video: 'видео',
      audio: 'аудио',
      presentation: 'презентации',
      image: 'изображения',
    };

    return dialogs.open('fileSelector', {
      isNeedFetch: true,
      fileType,
      Title: `Выбор ${titles[fileType]}`,
      maxWidth: fileType === 'audio' ? 600 : 900,
      success,
    });
  };
};

// --------------- WEBINAR ROOM ---------------

// TABS

export const createTab = (currentType: fileState['currentType']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    dispatch(setCurrentType(currentType));

    const titles: { [key: string]: string } = {
      video: 'видео',
      audio: 'аудио',
      presentation: 'презентации',
      image: 'изображения',
    };

    const success = async (selectedFiles: IFile[]) => {
      const fileIds = selectedFiles.map(({ _id }) => _id);

      const data = await asyncEmit('room:create-tabs', fileIds);
      dispatch(toggleAdminTab(false));
      return data;
    };

    return dialogs.open('fileSelector', {
      isNeedFetch: true,
      Title: `Выбор ${titles[currentType]}`,
      maxWidth: currentType === 'audio' ? 600 : 900,
      success,
    });
  };
};

export const tabCloseConfirm = (tabId: Tab['id']) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const success = async () => await asyncEmit('room:delete-tab', tabId);

    return dialogs.open('confirm', {
      Title: 'Закрытие вкладки',
      message: `Вы действительно хотите закрыть вкладку и убрать материал с трансляции?`,
      successButton: { danger: true, title: 'Закрыть' },
      success,
    });
  };
};

// RECORD

export const startRecordConfirm = (success: () => void) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.open('confirm', {
      Title: 'Запись мероприятия',
      message: 'Вы уверены, что хотите начать запись?',
      successButton: { title: 'Начать' },
      success,
    });
  };
};

export const stopRecordConfirm = (success: () => void) => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    return dialogs.open('confirm', {
      Title: 'Остановить запись',
      message: 'Вы действительно хотите остановить запись?',
      successButton: { danger: true, title: 'Остановить' },
      success,
    });
  };
};
