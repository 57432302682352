import { IPayloadError } from './index';
import { IFile } from './file';
import { IMessage } from './webinar';

export type userState = {
  user: IUser | null;
  draft: Pick<IUser, 'name' | 'about' | 'email' | 'city' | 'country' | 'socialLinks' | 'avatar'>;
  isAuth: boolean;
  isLoaded: boolean;
  replyMessage: IMessage | null;
  errorData: IPayloadError | null;
};

export interface IUser {
  _id: string;
  name: string;
  about?: string;
  email?: string;
  phone?: string;
  avatarGradient: { first: string; second: string };
  avatar?: IFile | null;
  isVerified: boolean;
  status: 'active' | 'banned';
  role: Role;
  ip: string;
  city: string;
  country: string;
  socialLinks: { link: string; social: Social }[];
  // createdAt: string;
  // updatedAt: string;
}

export interface AdminDashboardUserEditDraft
  extends Pick<IUser, '_id' | 'name' | 'about' | 'avatar' | 'role' | 'isVerified' | 'status'> {
  email: string;
  city?: string;
  country?: string;
}

export enum Role {
  VIEWER = 0,
  MEMBER = 1,
  MODERATOR = 2,
  ADMIN = 3,
}

export const roleLabels: Record<Role | number, string> = {
  [Role.VIEWER]: 'Зритель',
  [Role.MEMBER]: 'Участник',
  [Role.MODERATOR]: 'Модератор',
  [Role.ADMIN]: 'Администратор',
};

type Social = 'vk' | 'youtube' | 'instagram';
