import './index.less';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { popupFileSelectorSlice } from 'reducers/popupFileSelector';

const PrimaryButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#fff',
  color: 'black',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const ItemActions = ({ item, mainBtn, isSelected, isAction, maxSelected }) => {
  const dispatch = useDispatch();
  const { selectFile, cancelFile } = popupFileSelectorSlice.actions;
  const { selectedFiles } = useSelector((state) => state.popupFileSelector);

  const onSelect = () => dispatch(selectFile(item));
  const onCancel = () => dispatch(cancelFile(item));

  const actions = isSelected ? (
    <CloseButton color="error" onClick={onCancel}>
      <CloseIcon />
    </CloseButton>
  ) : (
    <>
      <PrimaryButton onClick={mainBtn.action}>
        <mainBtn.icon />
      </PrimaryButton>
      {selectedFiles.length < maxSelected && (
        <PrimaryButton onClick={onSelect}>
          <CheckIcon />
        </PrimaryButton>
      )}
    </>
  );

  const renderActions = () => {
    if (isAction) return actions;

    return (
      <PrimaryButton onClick={mainBtn.action}>
        <mainBtn.icon />
      </PrimaryButton>
    );
  };

  return <div className={cn('dialog-file-selector-item__overlay', { visible: isSelected })}>{renderActions()}</div>;
};

export default ItemActions;
