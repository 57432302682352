import SimpleBarReact from "simplebar-react";
import SimpleBarCore from "simplebar-core";

import { alpha, styled, SxProps, Theme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { forwardRef, ForwardedRef } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
  flexGrow: 1,
  height: "100%",
  overflow: "hidden",
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: "100%",
  "& .simplebar-scrollbar": {
    "&:before": {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 10,
  },
  "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
    height: 6,
  },
  "& .simplebar-mask": {
    zIndex: "inherit",
  },
}));

// ----------------------------------------------------------------------

interface IScrollbar {
  children: React.ReactElement | React.ReactElement[];
  sx?: SxProps<Theme>;
}

const Scrollbar = forwardRef<SimpleBarCore, IScrollbar>(
  ({ children, sx, ...other }, ref: ForwardedRef<SimpleBarCore>) => {
    const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    if (isMobile) {
      return (
        <Box sx={{ overflowX: "auto", ...sx }} {...other}>
          {children}
        </Box>
      );
    }

    return (
      <RootStyle>
        <SimpleBarStyle ref={ref} clickOnTrack={false} sx={sx} {...other}>
          {children}
        </SimpleBarStyle>
      </RootStyle>
    );
  }
);

export default Scrollbar;
