import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogProps } from './Dialog';

const Header: React.FC<DialogProps> = (props) => {
  const { Header: HeaderComp, close, Title = 'Диалоговое окно' } = props;

  if (typeof HeaderComp === `function` || typeof HeaderComp === `object`) return <HeaderComp {...props} />;

  return (
    <div className="dialog__header">
      <div className="dialog__title">{Title}</div>
      <IconButton size="small" onClick={close}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default Header;
