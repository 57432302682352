const num = (value: number) => Math.floor(value);

const timeFormat = (sec: number) => {
  if (sec === 0) return '0';

  const hours = (sec / 3600) % 24;
  const minutes = (sec / 60) % 60;
  const seconds = sec % 60;

  if (num(hours) === 0 && num(minutes) === 0) return `0:${seconds < 10 ? '0' + num(seconds) : num(seconds)}`;

  if (Math.floor(hours) === 0) return `${num(minutes)}:${seconds < 10 ? '0' + num(seconds) : num(seconds)}`;

  return `${num(hours)}:${num(minutes)}:${seconds < 10 ? '0' + num(seconds) : num(seconds)}`;
};

export default timeFormat;
