import { createPortal } from 'react-dom';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Item from './item';

const Default = ({
  left,
  top,
  items,
  className,
  onSelect,
  label,
  offset = 5,
  targetSizes: { clientTargetWidth, clientTargetHeight } = {},
}) => {
  const refNode = useRef(null);
  const [style, setStyle] = useState({});
  const paddings = 48;

  const changeStyle = useCallback((newStyle) => {
    setStyle((style) => ({ ...style, ...newStyle }));
  }, []);

  const fixPosition = () => {
    const { width, height } = refNode.current.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;

    if (width >= innerWidth - paddings) {
      refNode.current.classList.add('turn-left');
      changeStyle({ width: innerWidth - paddings });
    } else {
      refNode.current.classList.remove('turn-left');
      changeStyle({ left: left + clientTargetWidth - width, width });
    }

    if (top + height + offset + clientTargetHeight >= innerHeight) {
      refNode.current.classList.add('turn-top');
      changeStyle({ top: top - offset });
    } else {
      refNode.current.classList.remove('turn-top');
      changeStyle({ top: top + clientTargetHeight + offset });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fixPosition(), [top, left, clientTargetWidth, clientTargetHeight]);

  return createPortal(
    <div className={cn('menu', { [`${className}`]: className })} ref={refNode} style={style}>
      {label && <div className="menu-label">{label}</div>}

      <div className="menu-inner">
        {items.map((item, i) => (
          <Item key={i} item={item} onSelect={onSelect} />
        ))}
      </div>
    </div>,
    document.getElementById('context-menu-root')
  );
};

export default Default;
