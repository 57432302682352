import './index.less';
import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { hasClass } from 'utils';
import { closeContextMenu } from 'actions/contextMenu';
import { getContextMenu } from 'store/reducers/contextMenu';
import { useTypedDispatch, useTypedSelector } from 'store/index';

const ContextMenu = () => {
  const dispatch = useTypedDispatch();
  const menu = useTypedSelector(getContextMenu);

  const onClose = ({ target }: any) => {
    if (!hasClass(target, `menu-inner`)) {
      dispatch(closeContextMenu());
    }
  };

  useEffect(() => {
    window.addEventListener(`click`, onClose, true);
    window.addEventListener(`scroll`, onClose, true);
    return () => {
      window.removeEventListener(`click`, onClose, true);
      window.removeEventListener(`scroll`, onClose, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransitionGroup>
      {menu.map(({ Render, ...props }, i) => (
        <CSSTransition key={i} timeout={300} classNames="dialog">
          <Render key={i} {...props} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default ContextMenu;
