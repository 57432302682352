import './index.less';
import React, { memo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Result from 'components/Result';
import Arrows from './Arrows';
import Panel from './Panel';
import { sourceWithBaseUrl } from 'utils/index';
import CachedImage from 'components/CachedImage';

type Props = {
  close: () => void;
  source: string | string[] | undefined;
};

const Body: React.FC<Props> = memo(({ close, source }) => {
  const [index, setIndex] = useState<number>(0);
  const [rotationValue, setRotationValue] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const isMany = Array.isArray(source) && source.length > 1;
  const currentSlide = Array.isArray(source) ? source[index] : source;

  const toPrevImage = () => {
    setIsLoaded(false);
    setIsError(false);

    const value = index - 1 < 0 ? source!.length - 1 : index - 1;
    setIndex(value);
  };

  const toNextImage = () => {
    setIsLoaded(false);
    setIsError(false);

    const value = index < source!.length - 1 ? index + 1 : 0;
    setIndex(value);
  };

  const rotateLeft = () => setRotationValue((degrees) => degrees - 90);
  const rotateRight = () => setRotationValue((degrees) => degrees + 90);
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <div className="dialog-image-view">
      {isError ? (
        <Result
          type="error"
          color="error"
          maxWidth={540}
          subTitle="Мы не смогли загрузить Ваше изображение. Возможно, оно было удалено или к нему был изменен путь"
        />
      ) : (
        <>
          <Panel {...{ rotateLeft, rotateRight, stopPropagation, close }} />
          <div onClick={stopPropagation} className="dialog-image-view__content">
            {isMany && <Arrows {...{ toPrevImage, toNextImage }} />}
            {!isLoaded && (
              <div className="dialog-image-view__loader">
                <CircularProgress />
              </div>
            )}
            <CachedImage
              style={{ transform: `rotate(${rotationValue}deg)`, opacity: isLoaded ? 1 : 0 }}
              className="dialog-image-view__image"
              alt="Полноразмерное изображение"
              url={sourceWithBaseUrl(currentSlide)}
              onLoad={() => setIsLoaded(true)}
              onError={() => setIsError(true)}
            />
          </div>
        </>
      )}
    </div>
  );
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fullscreen: true,
  name: 'imageView',
  Body,
};
