import { io, Socket } from 'socket.io-client';
import { types as mediasoupTypes } from 'mediasoup-client';

import { API_URL } from 'utils/config';

import { roomState, Tab } from 'types/room';
import { IMessage, IPeer } from 'types/webinar';
import { CanvasFigure } from 'types/whiteboard';
import { IUser } from 'types/user';
import { IProducer } from 'types/rtc';
import { ProducerType } from 'types/devices';

export interface ISocketRes<D = any> {
  error?: {
    message: string;
  };
  isSuccess: boolean;
  data?: D;
}

interface ServerToClientEvents {
  // admin
  'user:is-speaker': () => void;
  chatMode: (chatMode: roomState['isChatAvailable']) => { payload: typeof chatMode; type: string };
  'server:lower-hands': () => void;

  // room
  'room:data': (data: roomState) => void;
  'room:new-tabs': (data: { tabs: roomState['tabs']; activeTabId: roomState['activeTabId'] }) => {
    payload: typeof data;
    type: string;
  };
  'room:deleted-tab': (data: { tabId: Tab['id']; activeTabId: roomState['activeTabId'] }) => {
    payload: typeof data;
    type: string;
  };
  'room:active-tab': (tabId: roomState['activeTabId']) => { payload: typeof tabId; type: string };
  'server:unblock-user': (userId: IUser['_id']) => void;
  'server:block-user': (userId: IUser['_id']) => void;

  // material
  'material:slide': ({ tabId, slideNumber }: { tabId: Tab['id']; slideNumber: number }) => void;
  'material:state-media': (state: 'start' | 'stop') => void;
  'material:currentTime-media': (seconds: number) => void;

  // whiteboard
  'whiteboard:figure': (figure: CanvasFigure) => void;
  'whiteboard:changed-figure': (figure: CanvasFigure) => void;
  'whiteboard:deleted-figure': (figureId: CanvasFigure['id']) => void;
  'whiteboard:blank-сanvas': () => void;

  // chat
  'chat:messages': (messages: IMessage[]) => void;
  'chat:new-message': (message: IMessage) => void;
  'chat:deleted-message': (messageId: IMessage['_id']) => void;

  // peers
  'server:get-peers': (peers: { [key: IPeer['id']]: IPeer }) => void;
  'server:connect-peer': (peer: IPeer) => void;
  'server:leave-peer': (peerId: IPeer['id']) => void;
  'client:allow-broadcast': (peerId: IPeer['id']) => void;
  'client:forbid-broadcast': (peerId: IPeer['id']) => void;
  'onlyMe:forbid-broadcast': () => void;
  'server:set-peer-hand': ({ isHandUp, userId }: { isHandUp: boolean; userId: IUser['_id'] }) => void;
  'onlyMe:allow-broadcast': () => void;

  // rtc
  consumerClosed: ({ consumerId, type }: { consumerId: string; type: ProducerType }) => void;
  newProducers: (data: IProducer[]) => void;
  'server::recording:toggle': ({ isRecording, startRecordTime }: { isRecording: boolean; startRecordTime: string | null }) => void;
  'server::recording:loading': () => void;
}

interface ClientToServerEvents {
  // room
  'room:get-data': () => void;
  'room:set-active-tab': (tabId: Tab['id']) => void;
  'client:lower-hands': () => void;
  'client:set-peer-hand': ({ isHandUp, userId }: { isHandUp: boolean; userId?: IUser['_id'] }) => void;

  // material
  'meterial:change-slide': ({ tabId, slideNumber }: { tabId: Tab['id']; slideNumber: number }) => void;
  'material:set-media-state': (state: 'start' | 'stop') => void;
  'meterial:seeked': (currentTime: number) => void;

  // whiteboard
  'whiteboard:canvas': (data: null, callback: (data: ISocketRes<CanvasFigure[]>) => void) => void;
  'whiteboard:add-figure': (figure: CanvasFigure) => void;
  'whiteboard:change-figure': (figure: CanvasFigure) => void;
  'whiteboard:delete-figure': (figureId: CanvasFigure['id']) => void;
  'whiteboard:clear': () => void;

  // chat
  'chat:switch-chatMode': (state: boolean) => void;
  'chat:add-message': (dto: { text: string; replyMessageId?: IMessage['_id'] }) => void;
  'chat:remove-message': (messageId: IMessage['_id']) => void;
  'chat:get-messages': () => void;

  // rtc
  producerClosed: (producerId: string) => void;
  getProducers: () => void;
  stopRecord: () => void;

  // peers
  'server:allow-broadcast': (peerId: string) => void;
  'server:forbid-broadcast': (peerId: string) => void;
  'user:leave-webinar': () => void;
}

interface AsyncClientToServerEvents {
  createWebRtcTransport: {
    payload: { forceTcp: boolean; rtpCapabilities?: mediasoupTypes.RtpCapabilities };
    res: ISocketRes<mediasoupTypes.TransportOptions>;
  };
  connectTransport: {
    payload: { transportId: string; dtlsParameters: mediasoupTypes.DtlsParameters };
    res: ISocketRes;
  };
  getRouterRtpCapabilities: {
    payload: null;
    res: ISocketRes<mediasoupTypes.RtpCapabilities>;
  };
  produce: {
    payload: {
      producerTransportId: string;
      kind: mediasoupTypes.MediaKind;
      rtpParameters: mediasoupTypes.RtpParameters;
      appData: any;
    };
    res: ISocketRes<string>;
  };
  startRecord: {
    payload: {
      producerTransportId: string;
      kind: mediasoupTypes.MediaKind;
      rtpParameters: mediasoupTypes.RtpParameters;
      appData: any;
    };
    res: ISocketRes<string>;
  };
  consume: {
    payload: {
      producerId: string;
      consumerTransportId: string;
      rtpCapabilities: mediasoupTypes.RtpCapabilities;
      appData: { type: ProducerType };
    };
    res: ISocketRes<{
      producerId: string;
      id: string;
      kind: mediasoupTypes.MediaKind;
      rtpParameters: mediasoupTypes.RtpParameters;
      producerPaused: boolean;
    }>;
  };
  'webinar:create-room': {
    payload: string;
    res: ISocketRes;
  };
  'user:join-webinar': {
    payload: { webinarId: string; browser: Bowser.Parser.ParsedResult };
    res: ISocketRes;
  };
  'room:create-tabs': {
    payload: string[];
    res: ISocketRes;
  };
  'room:delete-tab': {
    payload: string;
    res: ISocketRes;
  };
  'client:block-user': {
    payload: string;
    res: ISocketRes;
  };
  'client:unblock-user': {
    payload: string;
    res: ISocketRes;
  };
}

type CombinedClientToServerEvents = ClientToServerEvents & AsyncClientToServerEvents;

const socket: Socket<ServerToClientEvents, CombinedClientToServerEvents> = io(API_URL, {
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
  path: '/server',
});

type PayloadType<T extends keyof AsyncClientToServerEvents> = AsyncClientToServerEvents[T]['payload'] | {};

export const asyncEmit = async <T extends keyof AsyncClientToServerEvents>(
  type: T,
  payload: PayloadType<T> = {}
): Promise<AsyncClientToServerEvents[T]['res']> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    socket.emit<keyof AsyncClientToServerEvents>(type, payload, (res: AsyncClientToServerEvents[T]['res']) =>
      !res.isSuccess ? reject(res) : resolve(res)
    );
  });
};

export default socket;
