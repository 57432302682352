import './index.less';
import { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Add, ArrowBack } from '@mui/icons-material';

import List from './List';
import FileSearch from 'components/FileSearch';
import TabControl from 'components/Dashboard/Content/TabControl';

import { setCurrentType, setPage, setSearch } from 'store/reducers/file';
import { fetchFiles, getFileStatistic } from 'store/actions/file';
import { useTypedDispatch, useTypedSelector } from 'store/index';
import { FileType } from 'types/file';

const Body: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { filesByType, currentType, isLoading, page, perPage, search, files } = useTypedSelector((state) => state.file);

  useEffect(() => {
    dispatch(getFileStatistic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchFiles({ currentType, perPage, page, search }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType, page, search]);

  const [isLoadPart, setIsLoadPart] = useState(false);

  const onShowLoadPart = () => setIsLoadPart(true);
  const onHideLoadPart = () => setIsLoadPart(false);
  const onSearch = (value: string) => dispatch(setSearch(value));
  const changeFileType = ({ target }: SelectChangeEvent<keyof typeof FileType>) =>
    dispatch(setCurrentType(target.value as keyof typeof FileType));
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    console.log('roomMaterials page =', page);
    dispatch(setPage(page));
  };

  const pages = Math.ceil((search ? files.length : filesByType[currentType].count) / perPage) ?? 1;
  console.log(pages);
  const renderContent = () => {
    if (isLoadPart) {
      return (
        <>
          <Button sx={{ mb: 3 }} startIcon={<ArrowBack />} onClick={onHideLoadPart}>
            Вернуться к выбору
          </Button>
          <TabControl size="small" />
        </>
      );
    }

    return (
      <>
        <div className="room-materials__header">
          <FileSearch className="room-materials__search" onSearch={onSearch} />
          <FormControl className="room-materials__select" disabled={isLoading} size="small">
            <InputLabel id="file-select-label">Тип материала</InputLabel>
            <Select
              value={currentType}
              labelId="file-select-label"
              id="file-select"
              label="Тип материала"
              onChange={changeFileType}
            >
              <MenuItem value="presentation">Презентации</MenuItem>
              <MenuItem value="image">Изображение</MenuItem>
              <MenuItem value="video">Видео</MenuItem>
              <MenuItem value="audio">Аудио</MenuItem>
            </Select>
          </FormControl>
          <IconButton className="room-materials__add-btn" onClick={onShowLoadPart}>
            <Add />
          </IconButton>
        </div>
        <List />
        {pages > 1 && (
          <Stack mt={3} justifyContent="center" alignItems="center">
            <Pagination page={page} onChange={handlePagination} count={pages} color="primary" />
          </Stack>
        )}
      </>
    );
  };

  return (
    <div className="room-materials">
      {isLoading && (
        <div className="dialog__body-loader">
          <CircularProgress />
        </div>
      )}
      {renderContent()}
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'roomMaterials',
  Title: 'Выбор материала',
  Footer: false,
  maxWidth: '640px',
  Body,
};
