import './index.less';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Dialog from './Dialog';

import { closeDialog, closeAllDialogs } from 'actions/dialogs';
import { useTypedDispatch, useTypedSelector } from 'store/index';
import { getDialogs } from 'store/reducers/dialogs';

const Dialogs = () => {
  const location = useLocation();

  const dispatch = useTypedDispatch();
  const dialogs = useTypedSelector(getDialogs);

  const onClose = (index: number) => () => {
    dispatch(closeDialog(index));
  };

  useEffect(() => {
    dispatch(closeAllDialogs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <TransitionGroup>
      {dialogs.map(({ component = {}, options = {} }, index) => (
        <CSSTransition key={index} timeout={300} classNames="dialog">
          <Dialog
            key={index}
            closeAll={() => dispatch(closeAllDialogs())}
            close={onClose(index)}
            {...{ ...component, ...options }}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default Dialogs;
