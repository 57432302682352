import './index.less';
import { useTypedSelector } from 'store/index';

import Item from './Item';
import EmptyBox from 'components/EmptyBox';
import emptyPic from 'images/empty/delayed.svg';

const List = () => {
  const { files } = useTypedSelector((state) => state.file);

  return (
    <ul className="room-materials__list">
      {!!files.length ? (
        files.map((item, key) => <Item key={key} {...item} />)
      ) : (
        <EmptyBox severity="info" image={emptyPic} text="Файлы данного типа не найдены" />
      )}
    </ul>
  );
};

export default List;
