const openDatabase = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('image_cache', 1);
    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore('images', { keyPath: 'url' });
    };
    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };
    request.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
};

export const convertImageToBase64 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx!.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };
    image.src = url;
  });
};

export const cacheImage = async (url: string, base64Image: string): Promise<void> => {
  const db = await openDatabase();
  const transaction = db.transaction('images', 'readwrite');
  const imagesStore = transaction.objectStore('images');
  imagesStore.add({ url, data: base64Image });
};

export const getCachedImage = async (url: string): Promise<string | null> => {
  const db = await openDatabase();
  const transaction = db.transaction('images', 'readonly');
  const imagesStore = transaction.objectStore('images');
  const request = imagesStore.get(url);

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      resolve(result ? result.data : null);
    };
    request.onerror = (event) => {
      reject((event.target as IDBRequest).error);
    };
  });
};
