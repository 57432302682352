import { createApi, BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';

export interface CustomError {
  data: {
    message: string;
    fields?: { [key: string]: any };
  };
  status: number;
}

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  endpoints: () => ({}),
});

export default api;
