import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { userStreams } from 'types/index';
import { CanvasLine, CanvasRectCirc } from 'types/whiteboard';

export const selectVideoDevice = (state: RootState) => state.devices.selectedVideoDevice;
export const selectAudioDevice = (state: RootState) => state.devices.selectedAudioDevice;

export const selectCameraStreams = (state: RootState) => state.cameraStreams;
export const selectMicrophoneStreams = (state: RootState) => state.microphoneStreams;

export const selectUserStreams = createSelector(
  [selectCameraStreams, selectMicrophoneStreams, (state: RootState) => state.user],
  (cameraStreams, microphoneStreams, { user }) => {
    const userStreams: userStreams = {};

    for (const key in cameraStreams) {
      const { userInfo, stream } = cameraStreams[key];

      if (!userStreams[userInfo._id]) {
        userStreams[userInfo._id] = {
          isProducer: user?._id === userInfo._id,
          userInfo,
          cameraStream: null,
          microphoneStream: null,
        };
      }

      userStreams[userInfo._id].cameraStream = stream;
    }

    for (const key in microphoneStreams) {
      const { userInfo, stream } = microphoneStreams[key];

      if (!userStreams[userInfo._id]) {
        userStreams[userInfo._id] = {
          isProducer: user?._id === userInfo._id,
          userInfo,
          cameraStream: null,
          microphoneStream: null,
        };
      }

      userStreams[userInfo._id].microphoneStream = stream;
    }

    return userStreams;
  }
);

// --------------- WHITEBOARD ---------------
const isLine = (obj: any): obj is CanvasLine => !!obj && ['brush', 'eraser'].includes(obj.tool);
const isRect = (obj: any): obj is CanvasRectCirc => !!obj && obj.tool === 'rect';
const isCirc = (obj: any): obj is CanvasRectCirc => !!obj && obj.tool === 'circ';

export const selectWhiteboard = (state: RootState) => state.whiteboard;
const figures = createSelector(selectWhiteboard, ({ figures }) => figures);
export const getLines = createSelector(
  figures,
  (figures) => figures.filter((figure) => isLine(figure) && figure) as CanvasLine[]
);
export const getRectangles = createSelector(
  figures,
  (figures) => figures.filter((figure) => isRect(figure) && figure) as CanvasRectCirc[]
);
export const getCircles = createSelector(
  figures,
  (figures) => figures.filter((figure) => isCirc(figure) && figure) as CanvasRectCirc[]
);

// --------------- ROOM ---------------

const selectRoom = (state: RootState) => state.room;
export const getTabs = createSelector(selectRoom, ({ tabs }) => tabs);
export const getActiveTab = createSelector(selectRoom, ({ activeTabId, tabs }) =>
  activeTabId ? tabs[activeTabId] : null
);
export const getActiveTabId = createSelector(selectRoom, ({ activeTabId }) => activeTabId);

export const hasAnyStreams = createSelector(
  [(state) => state.cameraStreams, (state) => state.microphoneStreams],
  (cameraStreams, microphoneStreams) => !!Object.keys(cameraStreams).length || !!Object.keys(microphoneStreams).length
);
