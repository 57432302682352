import Button from '@mui/material/Button';
import { DialogProps } from './Dialog';

type Props = {
  isLoading: boolean;
  onCancel: () => Promise<void>;
  cancelButton?: DialogProps['cancelButton'];
};

const Cancel: React.FC<Props> = ({ onCancel, isLoading, cancelButton }) => {
  const { title = 'Отмена', disabled = false, hint } = cancelButton || {};

  return (
    <Button variant="outlined" onClick={onCancel} disabled={disabled || isLoading} title={hint}>
      {title}
    </Button>
  );
};

export default Cancel;
