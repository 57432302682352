import './index.less';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Controls from './Controls';
import Actions from './Actions';

import { useTypedDispatch, useTypedSelector } from 'store/index';
import { setIsPlaying, setTrackIndex, onClose } from 'reducers/audioPlayer';

import timeFormat from 'utils/timeFormat';
import { API_URL } from 'utils/config';
import { sourceWithBaseUrl } from 'utils/index';
import CachedImage from 'components/CachedImage';

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: 'white',
});

const AudioPlayer = () => {
  const dispatch = useTypedDispatch();
  const { volume, isPlaying, tracks, trackIndex } = useTypedSelector((state) => state.audioPlayer);

  const [trackProgress, setTrackProgress] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const startTimer = () => {
    if (!audioRef.current) return;

    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current!.ended && tracks.length === 1) return dispatch(setIsPlaying(false));
      if (audioRef.current!.ended && tracks.length !== 1) return toNextTrack();

      return setTrackProgress(audioRef.current!.currentTime);
    }, 250);
  };

  const handlePlayerClose = () => {
    if (isPlaying) dispatch(setIsPlaying(false));
    if (audioRef.current) audioRef.current.pause();

    clearInterval(intervalRef.current);
    dispatch(onClose());
  };

  const onChange = (event: Event, value: number | Array<number>, activeThumb: number) => {
    if (!audioRef.current) return;

    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value as number;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onChangeCommitted = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    if (!isPlaying) dispatch(setIsPlaying(true));
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) return dispatch(setTrackIndex(tracks.length - 1));
    return dispatch(setTrackIndex(trackIndex - 1));
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) return dispatch(setTrackIndex(trackIndex + 1));
    return dispatch(setTrackIndex(0));
  };

  useEffect(() => {
    if (audioRef.current) audioRef.current.volume = volume;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume]);

  useEffect(() => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current
        .play()
        .then((_) => startTimer())
        .catch((error) => console.log(error));
    } else {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    setTrackProgress(0);

    if (!audioRef.current || !tracks[trackIndex]) return;

    audioRef.current.load();

    fetch(`${API_URL}/${tracks[trackIndex].path}`)
      .then((response) => response.blob())
      .then((blob) => {
        if (!audioRef.current) throw new Error('Произошла ошибка');

        audioRef.current.src = URL.createObjectURL(blob);
        return audioRef.current.play();
      })
      .then(() => {
        if (!audioRef.current) throw new Error('Произошла ошибка');

        audioRef.current.volume = volume;
        setTrackProgress(audioRef.current.currentTime);
        dispatch(setIsPlaying(true));
        startTimer();
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackIndex]);

  return (
    <div className="audio-player">
      <Stack sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)', p: '4px 12px' }}>
        <div>
          <Slider
            defaultValue={0}
            value={trackProgress}
            step={0.3}
            min={0}
            max={audioRef.current?.duration || 100}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            size="small"
            sx={{
              color: '#fff',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 8,
                height: 8,
                transition: '0.2s cubic-bezier(.47,1.64,.41,.8)',
                '&:before': { boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)' },
                '&:hover, &.Mui-focusVisible': { boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}` },
                '&.Mui-active': { width: 20, height: 20 },
              },
              '& .MuiSlider-rail': { opacity: 0.28 },
            }}
          />
          {audioRef.current && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: -2,
              }}
            >
              <TinyText>{timeFormat(audioRef.current.currentTime)}</TinyText>
              <TinyText>{timeFormat(audioRef.current.duration)}</TinyText>
            </Box>
          )}
        </div>
        <Stack direction="row" spacing={2}>
          <Controls onPrevClick={toPrevTrack} onNextClick={toNextTrack} />
          <div className="audio-player__track">
            <CachedImage
              className="audio-player__track-cover"
              url={sourceWithBaseUrl(tracks[trackIndex]?.previewUrls.small)}
              alt={tracks[trackIndex]?.name || 'Ошибка чтения'}
            />
            <div className="audio-player__track-info">
              <div className="audio-player__track-title">{tracks[trackIndex]?.name || 'Ошибка чтения'}</div>
              <div className="audio-player__track-date">{moment(tracks[trackIndex]?.createdAt).format('LL')}</div>
            </div>
          </div>
          <Actions onClose={handlePlayerClose} />
        </Stack>
      </Stack>
      <audio ref={audioRef} preload="none">
        <source src="" type="audio/mp3" />
      </audio>
    </div>
  );
};

export default AudioPlayer;
