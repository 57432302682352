import './index.less';
import cn from 'classnames';

import imageTab from 'images/presentationTabs/image.png';
import presentationTab from 'images/presentationTabs/presentation.png';
import audioTab from 'images/presentationTabs/audio.png';
import videoTab from 'images/presentationTabs/video.png';

import { createTab } from 'actions/dialogs';
import { FileType } from 'types/file';
import { useTypedDispatch, useTypedSelector } from 'store/index';
import { selectWebinar } from 'store/reducers/webinar';

const { presentation, image, audio, video } = FileType;

const items: { img: string; label: string; type: FileType; disabled: boolean }[] = [
  { img: presentationTab, label: 'Презентация', type: presentation, disabled: false },
  { img: imageTab, label: 'Изображение', type: image, disabled: false },
  { img: audioTab, label: 'Аудио', type: audio, disabled: false },
  { img: videoTab, label: 'Видео', type: video, disabled: false },
];

type Props = {
  size?: string;
  isAbsolute?: boolean;
};

const TabControl: React.FC<Props> = ({ size, isAbsolute = false }) => {
  const dispatch = useTypedDispatch();
  const webinar = useTypedSelector(selectWebinar);

  const handleClick = (currentType: FileType) => {
    if (!webinar) return;

    return dispatch(createTab(currentType));
  };

  return (
    <div className={cn('tab-control', { '_is-absolute': isAbsolute })}>
      <div
        className={cn('tab-control__container-wrapper', {
          'tab-control__container-wrapper_small': size === 'small',
        })}
      >
        <div
          className={cn('tab-control__container', {
            'tab-control__container_small': size === 'small',
          })}
        >
          {items.map(({ label, img, type, disabled }, index) => (
            <div
              key={index}
              className={cn('tab-control__item', {
                'tab-control__item_disabled': disabled,
                'tab-control__item_small': size === 'small',
              })}
              onClick={() => handleClick(type)}
            >
              <img className="tab-control__image" src={img} alt={label} />
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabControl;
