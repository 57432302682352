import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  selectedFiles: [],
};

export const popupFileSelectorSlice = createSlice({
  name: 'popupFileSelector',
  initialState,
  reducers: {
    selectFile(state, action) {
      state.selectedFiles.push(action.payload);
    },
    cancelFile(state, action) {
      state.selectedFiles = state.selectedFiles.filter((file) => file._id !== action.payload._id);
    },
    clear(state) {
      state.selectedFiles = [];
    },
  },
});

export const { selectFile, cancelFile, clear } = popupFileSelectorSlice.actions;

export default popupFileSelectorSlice.reducer;
