import './index.less';
import React from 'react';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import calculateSpace from 'utils/calculateSpace';
import calculateTime from 'utils/calculateTime';
import { declOfNum, sourceWithBaseUrl } from 'utils/index';

import { openRoomMaterialsContextMenu } from 'actions/contextMenu';
import { useTypedSelector, useTypedDispatch } from 'store/index';
import { IFile } from 'types/file';
import CachedImage from 'components/CachedImage';

const Item: React.FC<IFile> = ({ previewUrls, name, _id, size, duration, slides }) => {
  const dispatch = useTypedDispatch();
  const { currentType } = useTypedSelector((state) => state.file);

  const onClickCtrl = ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { left, top } = currentTarget.getBoundingClientRect();

    const targetSizes = {
      clientTargetWidth: currentTarget.clientWidth,
      clientTargetHeight: currentTarget.clientHeight,
    };
    dispatch(openRoomMaterialsContextMenu(_id, { left, top, targetSizes }));
  };

  return (
    <li className="room-material-item">
      <CachedImage className="room-material-item__image" url={sourceWithBaseUrl(previewUrls?.small)} alt={name} />
      <div className="room-material-item__info">
        <div className="room-material-item__name">{name}</div>
        <div className="room-material-item__info-subline">
          <div className="room-material-item__subtext">{calculateSpace(size)}</div>
          {['video', 'audio'].includes(currentType) && (
            <div className="room-material-item__subtext">{calculateTime(duration!)}</div>
          )}
          {currentType === 'presentation' && (
            <div className="room-material-item__subtext">{`${slides!.length} ${declOfNum(slides!.length, [
              'слайд',
              'слайда',
              'слайдов',
            ])}`}</div>
          )}
        </div>
      </div>
      <div className="room-material-item__actions">
        <IconButton onClick={onClickCtrl} size="small">
          <MoreHorizIcon />
        </IconButton>
      </div>
    </li>
  );
};

export default Item;
