// mui
import { Box, Stack, useTheme, Typography } from "@mui/material";
// ui
import LazyLoadImage from "shared/ui/LazyLoadImage";
// urils
import { sourceWithBaseUrl } from "utils/index";
import socket from "utils/socket";

// ----------------------------------------------------------------------

type Props = {
  index: number;
  src: string | null;
  currentSlide: number;
  tabId: string;
};

const SlideSelectorElement: React.FC<Props> = ({ index, src, currentSlide, tabId }) => {
  const { palette } = useTheme();

  const handleClick = () => socket.emit("meterial:change-slide", { tabId, slideNumber: index });

  return (
    <Stack
      data-key={index}
      sx={{
        backgroundColor: currentSlide === index ? "#dbe9f5" : undefined,
        borderColor: currentSlide === index ? palette.primary.main : "#e0e3e7",
        borderLeft: `3px solid ${currentSlide === index ? palette.primary.main : "#e0e3e7"}`,
        "&:hover": {
          backgroundColor: currentSlide === index ? "#dbe9f5" : "#f6f6f6",
        },
      }}
      direction="row"
      alignItems="center"
      py={4}
      pr={4}
      onClick={handleClick}
    >
      <Box sx={{ minWidth: 40, pl: 4 }}>
        <Typography variant="subtitle2">{index + 1}</Typography>
      </Box>
      <LazyLoadImage
        ratio="16/9"
        src={sourceWithBaseUrl(src)}
        alt="Превью слайда материала"
        sx={{ objectFit: "contain" }}
      />
    </Stack>
  );
};

export default SlideSelectorElement;
