import React from 'react';

import CriticalError from 'components/CriticalError';
import { getDateString } from 'utils/dates';

export type CriticalErrorState = {
  error: string | null;
  time: string | null;
};

class ErrorBoundary extends React.Component<any & { children?: React.ReactNode }, CriticalErrorState> {
  state: CriticalErrorState = {
    error: null,
    time: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorText = error.message.length > 255 ? `${error.message.substring(0, 255)}...` : error.message;

    this.setState({
      error: errorText,
      time: getDateString(),
    });
  }

  render() {
    if (this.state.error) {
      return <CriticalError {...this.state} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
