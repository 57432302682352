import { DialogProps } from './Dialog';

const Body: React.FC<DialogProps> = (props) => {
  const { Body: BodyComp } = props;

  if (!BodyComp) return null;

  if (typeof BodyComp === `function` || typeof BodyComp === `object`) return <BodyComp {...props} />;

  return BodyComp;
};

export default Body;
