import './index.less';
import { memo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';

const Panel = memo(({ stopPropagation, rotateRight, rotateLeft, close }) => {
  return (
    <ul onClick={stopPropagation} className="dialog-image-view__actions">
      <li onClick={close}>
        <CloseIcon />
      </li>
      <li onClick={rotateRight}>
        <RotateRightIcon />
      </li>
      <li onClick={rotateLeft}>
        <RotateLeftIcon />
      </li>
    </ul>
  );
});

export default Panel;
