import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Role } from 'types/user';

export type UsersState = {
  status: 'active' | 'banned' | 'all';
  role: Role | 'all';
  search: string;
  perPage: number;
  page: number;
};

const initialState: UsersState = {
  status: 'all',
  role: 'all',
  search: '',
  perPage: 10,
  page: 1,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStatus(state, { payload }: PayloadAction<UsersState['status']>) {
      state.status = payload;
    },
    setRole(state, { payload }: PayloadAction<UsersState['role']>) {
      state.role = payload;
    },
    setPage(state, { payload }: PayloadAction<UsersState['page']>) {
      state.page = payload;
    },
    setPerPage(state, { payload }: PayloadAction<UsersState['perPage']>) {
      state.perPage = payload;
    },
    setSearch(state, { payload }: PayloadAction<UsersState['search']>) {
      state.page = 1;
      state.search = payload;
    },
  },
});

export const { setStatus, setRole, setPage, setPerPage, setSearch } = usersSlice.actions;

export default usersSlice.reducer;
