import { ContextMenuLibrary } from 'views/ContextMenu';
import { SvgIconProps } from '@mui/material';

type ContextMenuDispatcher = (payload: any) => void;

type menuElement = {
  id: number;
  name: string;
  icon?: (props: SvgIconProps) => JSX.Element;
  disabled?: boolean;
  danger?: boolean;
};

type Options = {
  uniq?: string;
  onSelect: (item: menuElement) => void;
  left: number;
  top: number;
  offset?: number;
  label?: string;
  items: menuElement[];
  className?: string;
  targetSizes: {
    clientTargetWidth: number;
    clientTargetHeight: number;
  };
};

export default class ContextService {
  _dispatcher: ContextMenuDispatcher | null = null;
  _library: ContextMenuLibrary;
  _config: Options[] = [];
  _uniqCurrent: string | null = null;

  constructor(dispatcher: ContextMenuDispatcher, library: ContextMenuLibrary) {
    this._dispatcher = dispatcher;
    this._library = library;
  }

  open(arg: keyof ContextMenuLibrary | Options, arg2?: Options) {
    const name = typeof arg === `string` ? arg : `default`;
    const options = typeof arg === `string` ? arg2 : arg;
    const Render = this._library[name] || this._library.default;
    const { uniq, ...normalizedOptions } = this._normalizeOptions(options!);
    const uniqId = uniq === undefined ? JSON.stringify(normalizedOptions) : uniq;

    if (this._uniqCurrent === uniqId) this.close();

    this._uniqCurrent = uniqId;

    const config = { Render, ...normalizedOptions };

    this._config = [config];
    this._dispatch();
  }

  close() {
    if (this.isOpen()) {
      this._uniqCurrent = null;
      this._config = [];
      this._dispatch();
    }
  }

  isOpen() {
    return !!this._config.length;
  }

  _normalizeOptions({ uniq, onSelect, left, top, label, items, className, targetSizes }: Options) {
    if (left === undefined || top === undefined) throw Error(`Incorrect positioning parameters "left" or "top"!`);

    if (!Array.isArray(items) || !items.length) throw Error(`Incorrect menu option parameter "items"!`);

    if (typeof onSelect !== `function`) throw Error(`Incorrect menu option parameter "onSelect"!`);

    return {
      uniq,
      onSelect: (item: menuElement) => {
        // @ts-ignore
        if (onSelect(item) !== false) this.close();
      },
      top,
      left,
      label,
      items,
      className,
      targetSizes,
    };
  }

  _dispatch() {
    if (!this._dispatcher) return;

    this._dispatcher(this._config);
  }
}
