import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { enUS } from '@mui/x-date-pickers/locales';

// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  interface Theme {
    typography: {
      fontFamily: string;
    };
  }
  interface TypographyOptions {
    typography?: {
      fontFamily?: string;
    };
  }

  interface BreakpointOverrides {
    xxxs: true;
    xxs: true;
    xxl: true;
  }
}

// ----------------------------------------------------------------------

interface iThemeProvider {
  children: React.ReactElement;
}

const ThemeProvider: React.FC<iThemeProvider> = ({ children }) => {
  let theme = createTheme(
    {
      spacing: 4,
      typography: {
        fontFamily: 'Muller, Arial, sans-serif',
        fontSize: 14,
        button: {
          textTransform: 'none',
          fontSize: 16,
          whiteSpace: 'nowrap',
        },
      },
      breakpoints: {
        values: {
          xxxs: 0,
          xxs: 320,
          xs: 480,
          sm: 768,
          md: 1024,
          lg: 1280,
          xl: 1440,
          xxl: 1920,
        },
        step: 0,
      },
    },
    {
      components: {
        MuiLocalizationProvider: {
          defaultProps: {
            localeText: {
              ...enUS.components.MuiLocalizationProvider.defaultProps.localeText,
              okButtonLabel: 'Принять',
              cancelButtonLabel: 'Отменить',
            },
          },
        },
      },
    },
    ruRU
  );

  theme = responsiveFontSizes(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
