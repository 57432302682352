import $api from 'utils/MainApi';
import { routeNames } from 'routes/routeNames';

export default class FileService {
  static async getFiles(data) {
    return $api.get(routeNames.USER_FILES_PAGE, { params: data });
  }

  static async getFileStatistic() {
    return $api.get(`${routeNames.USER_FILES_PAGE}/statistic`);
  }

  static async downloadFile(file) {
    return $api.get(`${routeNames.USER_FILES_PAGE}/download?_id=${file._id}`, {
      responseType: 'blob',
    });
  }

  static async deleteFile(fileId) {
    return $api.delete(`${routeNames.USER_FILES_PAGE}?_id=${fileId}`);
  }

  static async downloadRecord(recordId) {
    return $api.get(`/records/${recordId}/download`, {
      responseType: 'blob',
    });
  }
}
