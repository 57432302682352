import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import cn from 'classnames';

import CircularProgress from '@mui/material/CircularProgress';

import Overlay from './Overlay';
import Header from './Header';
import Body from './Body';
import Message from './Message';
import Footer from './Footer';

import { useTypedSelector } from 'store';
import { getDialogs } from 'store/reducers/dialogs';

type SuccessButton = {
  title?: string;
  disabled?: boolean;
  onSuccess: () => Promise<void>;
  isLoading?: boolean;
  danger?: boolean;
  hint: string;
};

type CancelButton = {
  title?: string;
  disabled?: boolean;
  onCancel: () => void;
  isLoading?: boolean;
  hint: string;
};

export type DialogProps = {
  closeAll: () => void;
  close: () => void;
  maxWidth?: number;
  maxHeight?: number;
  style?: React.CSSProperties;
  mixin?: string;
  isLoading?: boolean;
  fullscreen?: boolean;
  Header?: React.FC<DialogProps>;
  Body?: React.FC<DialogProps>;
  Footer?: boolean | React.FC<DialogProps>;
  cancel?: () => Promise<void>;
  success?: () => Promise<void>;
  Title?: string;
  message?: string;
  disableAction?: boolean;
  successButton?: SuccessButton;
  cancelButton?: CancelButton;
};

const Dialog: React.FC<DialogProps> = (props) => {
  const { maxWidth = 600, maxHeight, style, mixin, close, isLoading = false, fullscreen = false } = props;
  const dialogs = useTypedSelector(getDialogs);

  useEffect(() => {
    if (dialogs.length > 0) {
      document.body.classList.add('body__dialog-opened');
    }

    return () => {
      document.body.classList.remove('body__dialog-opened');
    };
  }, [dialogs]);

  const renderContent = () => {
    if (fullscreen) return <Body {...props} />;

    return (
      <div
        className={cn('dialog__content', { [`${mixin}`]: mixin })}
        style={{ maxWidth, maxHeight, ...style }}
        onClick={(evt) => evt.stopPropagation()}
      >
        <Header {...props} />
        <Message {...props} />
        <div className="dialog__body">
          {isLoading && (
            <div className="dialog__body-loader">
              <CircularProgress />
            </div>
          )}
          <Body {...props} />
        </div>
        <Footer {...props} />
      </div>
    );
  };

  return createPortal(
    <div className="dialog" onClick={close}>
      <Overlay />
      {renderContent()}
    </div>,
    document.getElementById('modal-root') as HTMLElement
  );
};

export default Dialog;
