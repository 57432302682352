import { IDevice, ProducerType } from "types/devices";
import { API_URL } from "./config";
import moment from "moment";
import { VideoQuality } from "types/roomHelpers";

export const hasClass = (node: HTMLElement, className: string) =>
  node && node.classList ? node.classList.contains(className) : false;

export type ConstraintsConfig = {
  video: {
    screen: boolean;
    audio: boolean;
    mediaConstraints: MediaStreamConstraints;
  };
  audio: {
    screen: boolean;
    audio: boolean;
    mediaConstraints: MediaStreamConstraints;
  };
  screen: { screen: boolean; audio: boolean };
};

const sizesOfQuality = {
  "360": { width: 480, height: 360 },
  "480": { width: 640, height: 480 },
  "720": { width: 960, height: 720 },
  "1080": { width: 1440, height: 1080 },
  auto: { width: { min: 480, ideal: 1440 }, height: { min: 360, ideal: 1080 } },
};

// const qualityPresets = [
//   { label: '360p', width: 480, height: 360, aspectRatio: '4:3' },
//   { label: '480p', width: 640, height: 480, aspectRatio: '4:3' },
//   { label: '720p', width: 960, height: 720, aspectRatio: '4:3' },
//   { label: '1080p', width: 1440, height: 1080, aspectRatio: '4:3' },

//   { label: '360p', width: 640, height: 360, aspectRatio: '16:9' },
//   { label: '480p', width: 854, height: 480, aspectRatio: '16:9' },
//   { label: '720p', width: 1280, height: 720, aspectRatio: '16:9' },
//   { label: '1080p', width: 1920, height: 1080, aspectRatio: '16:9' },
// ];

export const getConfig = (type: ProducerType, videoQuality: VideoQuality, deviceId?: IDevice["deviceId"]) => {
  const config: ConstraintsConfig = {
    video: {
      screen: false,
      audio: false,
      mediaConstraints: {
        audio: false,
        video: {
          ...sizesOfQuality[videoQuality],
          deviceId,
        },
      },
    },
    audio: {
      screen: false,
      audio: true,
      mediaConstraints: { audio: { deviceId }, video: false },
    },
    screen: { screen: true, audio: false },
  };

  return config[type];
};

export const cropText = (text: string, amount: number) => {
  if (text.length > amount) return text.slice(0, amount - 5) + "...";
  return text;
};

export const declOfNum = (number: number, words: string[]) =>
  words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]];

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
};

export const sourceWithBaseUrl = (path: string | undefined | null): string | undefined => {
  return path ? `${API_URL}/${path}` : undefined;
};

export const getTimeLeftUntilDeletion = (date: string | Date) => {
  const deletionDate = moment(date).add(30, "days");
  const now = moment();
  const duration = moment.duration(deletionDate.diff(now));

  const days = Math.floor(duration.asDays());
  const hours = duration.subtract(days, "days").hours();

  const daysStr = days ? `${days} ${declOfNum(days, ["день", "дня", "дней"])}` : "";
  const hoursStr = hours ? ` и ${hours} ${declOfNum(hours, ["час", "часа", "часов"])}` : "";

  return `${daysStr ? daysStr : ""}${hoursStr ? hoursStr : ""}`;
};
