import ItemVideo from './Video';
import ItemImage from './Image';
import ItemAudio from './Audio';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  video: ItemVideo,
  image: ItemImage,
  presentation: ItemImage,
  audio: ItemAudio,
};

export const optionsPresets = {
  video: {
    text: 'Загрузить видеозапись',
    accept: 'video/mp4',
    allowedExtensions: ['mp4'],
    allowedTypes: ['mp4'],
    declinationArray: ['видеозапись', 'видеозаписи', 'видеозаписей'],
  },
  audio: {
    text: 'Загрузить аудиозапись',
    accept: 'audio/mp3',
    allowedExtensions: ['mp3'],
    allowedTypes: ['mpeg'],
    declinationArray: ['аудиозапись', 'аудиозаписи', 'аудиозаписей'],
  },
  presentation: {
    text: 'Загрузить презентацию',
    // accept: '.doc, .docx, .ppt, .pptx, .pdf',
    accept: '.pdf',
    allowedExtensions: [
      'pdf',
      // 'doc', 'docx', 'ppt', 'pptx'
    ],
    allowedTypes: [
      'pdf',
      // 'msword',
      // 'vnd.openxmlformats-officedocument.wordprocessingml.document',
      // 'vnd.ms-powerpoint',
      // 'vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    declinationArray: ['презентацию', 'презентации', 'презентаций'],
  },
  image: {
    text: 'Загрузить изображение',
    accept: 'image/jpeg,image/png,image/jpg,image/svg+xml',
    allowedExtensions: ['jpeg', 'png', 'jpg'],
    allowedTypes: ['jpeg', 'jpg', 'png'],
    declinationArray: ['изображение', 'изображения', 'изображений'],
  },
};
