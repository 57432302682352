import api from 'utils/api';
import socket from 'utils/socket';
import { IMessage, IPeer } from 'types/webinar';

export const roomApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMessages: build.query<IMessage[], void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          socket.on('chat:messages', (messages) => {
            updateCachedData(() => messages);
          });

          socket.on('chat:new-message', (message) => {
            updateCachedData((draft) => {
              draft.push(message);
            });
          });

          socket.on('chat:deleted-message', (messageId) => {
            updateCachedData((draft) => draft.filter(({ _id }) => _id !== messageId));
          });
        } catch (err) {
          console.log(err);
        }

        await cacheEntryRemoved;
        socket.off('chat:messages');
        socket.off('chat:new-message');
        socket.off('chat:deleted-message');
      },
    }),
    getPeers: build.query<{ [peerId: IPeer['id']]: IPeer }, void>({
      queryFn: () => ({ data: {} }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          await cacheDataLoaded;

          socket.on('server:get-peers', (peers) => {
            updateCachedData(() => peers);
          });

          socket.on('server:connect-peer', (peer) => {
            updateCachedData((draft) => {
              if (!draft[peer.id]) draft[peer.id] = peer;
            });
          });

          socket.on('server:leave-peer', (peerId) => {
            updateCachedData((draft) => {
              if (draft[peerId]) delete draft[peerId];
            });
          });
        } catch (err) {
          console.log(err);
        }

        await cacheEntryRemoved;
        socket.off('server:get-peers');
        socket.off('server:connect-peer');
        socket.off('server:leave-peer');
      },
    }),
  }),
});

export const { useGetMessagesQuery, useGetPeersQuery } = roomApi;
