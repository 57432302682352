// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

import "./index.less";
import { CSSTransition } from "react-transition-group";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

import ContextMenu from "components/ContextMenu";
import Dialogs from "components/Dialogs";
import Uploader from "../Uploader";
import BackToTop from "../BackToTop";
import AudioPlayer from "../AudioPlayer";
import ErrorBoundary from "components/ErrorBoundary";

import Router from "routes/Router";

import { useTypedSelector } from "store/index";
import socket from "utils/socket";
import { useCheckAuthQuery } from "store/actions/user";

import MotionLazyContainer from "pages/Admin/shared/ui/animate/MotionLazyContainer";
import { ProgressBarStyle } from "pages/Admin/shared/ui/LoadingScreen/ui/ProgressBar";
import ThemeProvider from "../../theme";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import RoomProvider from "../../app/providers/RoomProvider";
import { TracksProvider } from "shared/contexts/tracksContext";

const App: React.FC = () => {
  const { isAuth } = useTypedSelector((state) => state.user);
  const { isOpen: isPlayerOpen } = useTypedSelector((state) => state.audioPlayer);
  const { isOpen: isUploaderOpen } = useTypedSelector((state) => state.uploader);

  useCheckAuthQuery();

  useEffect(() => {
    if (isAuth) socket.connect();
    if (!isAuth) socket.disconnect();
  }, [isAuth]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider>
        <MotionLazyContainer>
          <ErrorBoundary>
            <ProgressBarStyle />
            <TracksProvider>
              <RoomProvider>
                <section className="app">
                  <Router />
                  <BackToTop />
                  <Dialogs />
                  <ContextMenu />
                  <CSSTransition in={isPlayerOpen} classNames="audio-player" mountOnEnter unmountOnExit timeout={300}>
                    <AudioPlayer />
                  </CSSTransition>
                  <CSSTransition
                    in={isUploaderOpen}
                    classNames="upload-wrapper"
                    mountOnEnter
                    unmountOnExit
                    timeout={300}
                  >
                    <Uploader />
                  </CSSTransition>
                  <ToastContainer
                    theme="colored"
                    draggablePercent={60}
                    autoClose={3000}
                    closeOnClick
                    closeButton
                    limit={3}
                    pauseOnFocusLoss={false}
                  />
                </section>
              </RoomProvider>
            </TracksProvider>
          </ErrorBoundary>
        </MotionLazyContainer>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
