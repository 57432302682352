import axios from 'axios';
import { API_URL } from './config';
import { routeNames } from 'routes/routeNames';

const $api = axios.create({ withCredentials: true, baseURL: API_URL });

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return config;
});

$api.interceptors.response.use(
  (config) => config,
  async (err) => {
    const originalRequest = err.config;
    // eslint-disable-next-line eqeqeq
    if (err.response?.status == 401 && err.config && !err.config._isRetry) {
      originalRequest._isRetry = true;
      
      try {
        const response = await axios.get(`${API_URL}${routeNames.REFRESH}`, { withCredentials: true });
        localStorage.setItem('token', response.data.accessToken);

        return $api.request(originalRequest);
      } catch (error) {
        console.log('Вы не авторизованы');
      }
    }
    throw err;
  }
);

export default $api;
