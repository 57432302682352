import './index.less';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import ItemActions from './Actions';
import timeFormat from 'utils/timeFormat';

import { openVideoView } from 'actions/dialogs';

// ui
import LazyImage from 'shared/ui/LazyImage';

const ItemVideo = ({ item, maxSelected, isAction = false }) => {
  const dispatch = useDispatch();
  const { selectedFiles } = useSelector((state) => state.popupFileSelector);
  const { _id: itemId, previewUrls, name, path, duration } = item;

  const isSelected = selectedFiles.some(({ _id }) => _id === itemId);
  const action = () => dispatch(openVideoView(path));

  return (
    <div className="dialog-file-selector-item">
      <LazyImage src={previewUrls.small} thumb={previewUrls.thumb} alt={`Фрагмент видеофайла ${name}`} />
      <div className={cn('dialog-file-selector-item__name', { hidden: isSelected })}>{name}</div>
      <div className={cn('dialog-file-selector-item__info', { hidden: isSelected })}>{timeFormat(duration)}</div>
      <ItemActions
        isAction={isAction}
        maxSelected={maxSelected}
        isSelected={isSelected}
        item={item}
        mainBtn={{ icon: PlayArrowRoundedIcon, action }}
      />
    </div>
  );
};

export default ItemVideo;
