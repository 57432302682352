import './index.less';
import cn from 'classnames';

import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { selectFile, cancelFile } from 'reducers/popupFileSelector';
import { addTrack, setIsPlaying } from 'reducers/audioPlayer';

import timeFormat from 'utils/timeFormat';
import { useTypedSelector, useTypedDispatch } from 'store/index';
import { IFile } from 'types/file';
import { sourceWithBaseUrl } from 'utils/index';
import CachedImage from 'components/CachedImage';

const PrimaryButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#fff',
  color: 'black',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

type Props = {
  isAction: boolean;
  maxSelected: number;
  item: IFile;
};

const ItemAudio: React.FC<Props> = ({ item, isAction, maxSelected }) => {
  const dispatch = useTypedDispatch();
  const { tracks, trackIndex, isPlaying } = useTypedSelector((state) => state.audioPlayer);
  const { selectedFiles } = useTypedSelector((state) => state.popupFileSelector);

  const { _id: itemId, previewUrls, name = 'Название трека', duration } = item;

  const isSelected = selectedFiles.some(({ _id }) => _id === itemId);

  const onSelect = () => dispatch(selectFile(item));
  const onCancel = () => dispatch(cancelFile(item));
  const onPlay = () => dispatch(addTrack(item));
  const onStop = () => dispatch(setIsPlaying(false));

  const renderAction = () => {
    if (!isAction) return null;

    if (isSelected) {
      return (
        <CloseButton color="error" onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
      );
    }

    if (selectedFiles.length < maxSelected) {
      return (
        <PrimaryButton color="primary" onClick={onSelect}>
          <CheckIcon />
        </PrimaryButton>
      );
    }
  };

  return (
    <div className="dialog-file-selector-audio">
      <div className="dialog-file-selector-audio__cover">
        <CachedImage
          className="dialog-file-selector-audio__image"
          url={sourceWithBaseUrl(previewUrls.small)}
          alt={name}
        />
        <div
          className={cn('dialog-file-selector-audio__overlay', {
            'dialog-file-selector-audio__overlay_active': isPlaying && itemId === tracks[trackIndex]?._id,
          })}
        >
          <div className="dialog-file-selector-audio__bubble">
            {isPlaying && itemId === tracks[trackIndex]?._id ? (
              <div onClick={onStop} className="dialog-file-selector-audio__bubble-pause" />
            ) : (
              <div onClick={onPlay} className="dialog-file-selector-audio__bubble-play" />
            )}
          </div>
        </div>
      </div>
      <div className="dialog-file-selector-audio__data">
        <div className="dialog-file-selector-audio__name">{name}</div>
        <div className="dialog-file-selector-audio__duration">{timeFormat(duration!)}</div>
        {renderAction()}
      </div>
    </div>
  );
};

export default ItemAudio;
