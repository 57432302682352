import './index.less';
import { useRef, useState } from 'react';
import LazyImageItem from './Item';
import { useIntersectionObserver } from 'shared/hooks';

type Props = {
  position?: React.CSSProperties['objectPosition'];
  objectFit?: React.CSSProperties['objectFit'];
  src?: string;
  thumb?: string;
  alt?: string;
  ratio?: number;
};

const LazyImage: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting && ref.current) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div ref={ref} style={{ paddingBottom: props.ratio ? `${props.ratio}%` : '100%' }} className="lazy-image">
      {isVisible && <LazyImageItem {...props} />}
    </div>
  );
};
export default LazyImage;
