// current datetime as dd.mm.yyyy hh:mm
export const getDateString = () => {
  const date = new Date();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const hh = date.getHours();
  const mn = date.getMinutes();

  return `${hh}:${mn < 10 ? `0${mn}` : mn} - ${dd < 10 ? `0${dd}` : dd}.${mm < 10 ? `0${mm}` : mm}.${yyyy}`;
};
