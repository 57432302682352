import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CanvasFigure, WhiteboardState } from 'types/whiteboard';

const initialState: WhiteboardState = {
  stroke: '#eb144c',
  fill: '#eb144c',
  strokeWidth: 8,
  settingsTabIsOpen: false,
  settingsTab: null,
  tool: null,
  stage: null,
  layer: null,
  figures: [],
  history: [],
  selectedShape: null,
};

export const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    setData(state, { payload }: PayloadAction<Pick<WhiteboardState, 'stage' | 'layer'>>) {
      // @ts-ignore
      state.stage = payload.stage;
      // @ts-ignore
      state.layer = payload.layer;
    },
    setCanvas(state, { payload }: PayloadAction<CanvasFigure[] | undefined>) {
      state.figures = payload && Array.isArray(payload) ? payload : [];
    },
    addFigure(state, { payload }: PayloadAction<CanvasFigure>) {
      if (state.history.length > 0) {
        state.history = [];
      }

      state.figures.push(payload);
    },
    deleteFigure(state, { payload }: PayloadAction<CanvasFigure['id']>) {
      state.figures = state.figures.filter(({ id }) => id !== payload);
    },
    changeFigure(state, { payload }: PayloadAction<CanvasFigure>) {
      state.figures = state.figures.map((figure) => {
        if (figure.id === payload.id) return payload;

        return figure;
      });
    },
    setTool(state, { payload }: PayloadAction<CanvasFigure['tool'] | null>) {
      state.selectedShape = null;

      if (payload && ['brush', 'eraser'].includes(payload)) {
        state.settingsTab = 'brush';
        state.settingsTabIsOpen = !state.settingsTabIsOpen;
      } else {
        state.settingsTabIsOpen = false;
      }

      state.tool = payload;
    },
    selectShape(state, { payload }: PayloadAction<WhiteboardState['selectedShape']>) {
      if (state.selectedShape !== payload) {
        state.selectedShape = payload;
        state.tool = null;
      }
    },
    showSettingsTab(state, { payload }: PayloadAction<WhiteboardState['settingsTab']>) {
      state.settingsTab = payload;
      state.settingsTabIsOpen = true;
    },
    closeSettingsTab(state) {
      state.settingsTabIsOpen = false;
    },
    changeStrokeWidth(state, { payload }: PayloadAction<string>) {
      state.strokeWidth = parseInt(payload);
    },
    changeStroke(state, { payload }: PayloadAction<WhiteboardState['stroke']>) {
      state.stroke = payload;
    },
    // ----- history -----
    undo(state) {
      const lastFigure = state.figures.pop();
      if (lastFigure) {
        state.history.push(lastFigure);
      }
    },
    redo(state) {
      const lastFigure = state.history.pop();
      if (lastFigure) {
        state.figures.push(lastFigure);
      }
    },
    clear(state) {
      state.figures = [];
      state.history = [];
    },
  },
});

export const {
  setData,

  setCanvas,
  addFigure,
  changeFigure,
  deleteFigure,
  clear,

  setTool,
  selectShape,
  changeStroke,
  changeStrokeWidth,
  showSettingsTab,
  closeSettingsTab,

  undo,
  redo,
} = whiteboardSlice.actions;

export default whiteboardSlice.reducer;
