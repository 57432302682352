import './index.less';
import React from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import emptyPic from 'images/empty/error-in-calendar.svg';

type Props = {
  text?: string;
  severity?: AlertColor;
  title?: string;
  image?: string;
  children?: React.ReactNode;
};

const EmptyBox: React.FC<Props> = ({ title, text = 'Нет данных', severity, image, children }) => {
  return (
    <div className="empty-box">
      <img className="empty-box__image" src={image || emptyPic} alt="Изображение пустоты" />
      <Alert severity={severity}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </Alert>
      {children}
    </div>
  );
};

export default EmptyBox;
