import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileType } from 'types/file';

export type UploaderFile = {
  type: keyof typeof FileType;
  name: string;
  progress: number;
  size: number;
  _id: string;
  error: boolean;
};

type UploaderState = {
  uploadedFiles: UploaderFile[];
  isCollapsed: boolean;
  isOpen: boolean;
};

const initialState: UploaderState = {
  uploadedFiles: [],
  isCollapsed: false,
  isOpen: false,
};

export const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    setIsVisible(state, { payload }: PayloadAction<boolean>) {
      state.isCollapsed = payload;
    },
    closeUploaderWindow(state) {
      state.isOpen = false;
      state.uploadedFiles = [];
    },
    addUploadFile(state, { payload }: PayloadAction<UploaderFile>) {
      state.uploadedFiles.push(payload);
      state.isCollapsed = true;
      state.isOpen = true;
    },
    changeUploadFile(state, { payload }: PayloadAction<UploaderFile>) {
      state.uploadedFiles = state.uploadedFiles.map((file) =>
        file._id === payload._id ? { ...file, progress: payload.progress } : { ...file }
      );
    },
    setErrorToFile(state, { payload }: PayloadAction<Pick<UploaderFile, '_id' | 'error'>>) {
      state.uploadedFiles = state.uploadedFiles.map((file) =>
        file._id === payload._id ? { ...file, error: payload.error } : { ...file }
      );
    },
  },
});

export const { setIsVisible, closeUploaderWindow, addUploadFile, changeUploadFile, setErrorToFile } =
  uploaderSlice.actions;

export default uploaderSlice.reducer;
