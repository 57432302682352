import { types as mediasoupTypes } from "mediasoup-client";

import { addCameraStream, deleteCameraStream, replaceStream } from "reducers/cameraStreams";
import { addMicrophoneStream, deleteMicrophoneStream } from "reducers/microphoneStreams";
import {
  addProducer,
  deleteProducer,
  addProducerLabel,
  deleteProducerLabel,
  addConsumer,
  deleteConsumer,
  showScreen,
  closeScreen,
  deleteRecordProducers,
  deleteRecordStreams,
  addRecordProducer,
  addRecordStream,
} from "reducers/rtc";

import { Dispatch, GetState } from "store";
import { toggleAdminTab } from "store/reducers/roomHelpers";

import { IDevice, ProducerType } from "types/devices";
import { IUser } from "types/user";
import { IPeer } from "types/webinar";

import socket, { asyncEmit } from "utils/socket";
import { ConstraintsConfig, getConfig } from "utils";
import { Services } from "core";
import { VideoQuality } from "types/roomHelpers";

export const blockUser = (userId: IUser["_id"]) => {
  return async (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const { error } = await asyncEmit("client:block-user", userId);

    if (error) {
      dialogs.open("alert", { Title: "Произошла ошибка", message: error.message });
    }
  };
};

export const unblockUser = (userId: IUser["_id"]) => {
  return async (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const { error } = await asyncEmit("client:unblock-user", userId);

    if (error) {
      dialogs.open("alert", { Title: "Произошла ошибка", message: error.message });
    }
  };
};

export const setHand = (isHandUp: boolean, userId?: IUser["_id"]) => async () => {
  return socket.emit("client:set-peer-hand", { isHandUp, userId });
};

export const lowerAllHands = () => async () => {
  return socket.emit("client:lower-hands");
};

// Broadcast

export const allowBroadcast = (peerId: IPeer["id"]) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { peers } = getState().webinar;

    const countBroadcastAllows = Object.values(peers).reduce((acc, curr) => {
      if (curr.isBroadcastAllowed) acc++;
      return acc;
    }, 0);

    if (countBroadcastAllows >= 4) return;

    return socket.emit("server:allow-broadcast", peerId);
  };
};

export const forbidBroadcast = (peerId: IPeer["id"]) => async () => {
  return socket.emit("server:forbid-broadcast", peerId);
};

// Start Device Stream

export const startCamera = () => async (dispatch: Dispatch, getState: GetState) => {
  const { deviceId } = getState().devices.selectedVideoDevice || {};

  return dispatch(produce("video", deviceId));
};

export const startAudio =
  (audioContext: AudioContext | null, destination: MediaStreamAudioDestinationNode | null) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { deviceId } = getState().devices.selectedAudioDevice || {};

    return dispatch(produce("audio", deviceId, audioContext, destination));
  };

export const startScreen = () => async (dispatch: Dispatch, getState: GetState) => {
  return dispatch(produce("screen"));
};

export const startRecord = (
  stream: MediaStream,
  audioContext: AudioContext,
  destination: MediaStreamAudioDestinationNode
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { device, producerTransport } = getState().rtc;

    const microphoneStreams = getState().microphoneStreams;

    if (!device || !producerTransport) return console.error("Device error");
    if (!device.canProduce("video") || !device.canProduce("audio")) return console.error(`Cannot produce`);

    const videoParams: mediasoupTypes.ProducerOptions = {
      track: stream.getVideoTracks()[0],
      encodings: [
        { rid: "r0", maxBitrate: 200000, scalabilityMode: "S1T3" },
        { rid: "r1", maxBitrate: 400000, scalabilityMode: "S1T3" },
        { rid: "r2", maxBitrate: 1200000, scalabilityMode: "S1T3" },
      ],
      // codec: device.rtpCapabilities.codecs!.find((codec) => codec.mimeType === "video/H264"),
      codecOptions: { videoGoogleStartBitrate: 1000 },
      appData: { isRecording: true },
    };

    const videoProducer = await producerTransport.produce(videoParams);

    dispatch(addRecordProducer(videoProducer));
    dispatch(addRecordStream(stream));

    videoProducer.on("trackended", () => {
      dispatch(stopRecord(audioContext, destination));
    });
    videoProducer.on("transportclose", () => {
      dispatch(stopRecord(audioContext, destination));
    });

    try {
      await audioContext.resume();
    } catch (error) {
      console.error("Error resuming audio context:", error);
    }

    for (const microphoneStream of Object.values(microphoneStreams)) {
      const sourceNode = audioContext.createMediaStreamSource(microphoneStream.stream);
      sourceNode.connect(destination);
    }

    const oscillator = audioContext.createOscillator();
    oscillator.connect(destination);
    oscillator.frequency.value = 0;
    oscillator.start();

    const audioProducer = await producerTransport.produce({
      track: destination.stream.getAudioTracks()[0],
      appData: { isRecording: true },
    });

    dispatch(addRecordProducer(audioProducer));
    dispatch(addRecordStream(destination.stream));

    audioProducer.on("trackended", () => {
      dispatch(stopRecord(audioContext, destination));
    });
    audioProducer.on("transportclose", () => {
      dispatch(stopRecord(audioContext, destination));
    });
  };
};

export const stopRecord = (audioContext: AudioContext, destination: MediaStreamAudioDestinationNode) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { recordProducers, recordStreams } = getState().rtc;

    socket.emit("stopRecord");

    audioContext.suspend().then(() => {
      destination.stream.getTracks().forEach((track) => {
        destination.stream.removeTrack(track);
      });

      recordProducers.forEach((producer) => {
        producer.close();
      });

      dispatch(deleteRecordProducers());

      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });

      dispatch(deleteRecordStreams());

      audioContext?.close();
    });
  };
};

export const updateTrackQuality = (newQuality: VideoQuality) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { producerLabel, producers } = getState().rtc;

    if (!producerLabel["video"]) return;

    const { selectedVideoDevice } = getState().devices;
    const producerId = producerLabel["video"]!;

    const { mediaConstraints } = getConfig(
      "video",
      newQuality,
      selectedVideoDevice!.deviceId
    ) as ConstraintsConfig["video"];

    try {
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      const track = stream.getVideoTracks()[0];
      await producers[producerId].replaceTrack({ track });

      dispatch(replaceStream({ producerId, stream }));
    } catch (error) {
      console.error("Ошибка при получении потока:", error);

      if (["OverconstrainedError", "ConstraintNotSatisfiedError"].includes((error as Error).name)) {
        const { mediaConstraints } = getConfig(
          "video",
          "auto",
          selectedVideoDevice!.deviceId
        ) as ConstraintsConfig["video"];

        try {
          const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
          const track = stream.getVideoTracks()[0];
          await producers[producerId].replaceTrack({ track });

          dispatch(replaceStream({ producerId, stream }));
        } catch (error) {
          console.error("Не удалось получить видео с автоматическими размерами:", error);
        }
      } else {
        console.error("Не удалось получить видео:", error);
      }
    }
  };
};

// Produce

const produce = (
  type: ProducerType,
  deviceId?: IDevice["deviceId"],
  audioContext?: AudioContext | null,
  destination?: MediaStreamAudioDestinationNode | null
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { device, producerTransport, producerLabel } = getState().rtc;

    const { isSpeaker, videoQuality } = getState().roomHelpers;
    const { isRecording } = getState().room;
    const { user } = getState().user;

    const config = getConfig(type, videoQuality, deviceId);
    const { name, _id } = user!;
    const { audio, screen } = config;
    const mediaConstraints = "mediaConstraints" in config ? config.mediaConstraints : undefined;

    if (!device || !producerTransport) return console.error("Device error");
    if (!device.canProduce("video") && !audio) return console.error("Cannot produce video");
    if (producerLabel[type]) return console.log("Producer already exists for this type " + type);

    console.log("Mediacontraints:", mediaConstraints);

    try {
      const stream = screen
        ? await navigator.mediaDevices.getDisplayMedia()
        : await navigator.mediaDevices.getUserMedia(mediaConstraints);

      const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
      const params: mediasoupTypes.ProducerOptions = {
        track,
        appData: { type, isSpeaker },
      };

      // if (!audio) {
      //   params.codec = device.rtpCapabilities.codecs!.find((codec) => codec.mimeType === "video/H264");
      // }

      if (!audio && !screen) {
        params.encodings = [
          { rid: "r0", maxBitrate: 100000, scalabilityMode: "S1T3" },
          { rid: "r1", maxBitrate: 300000, scalabilityMode: "S1T3" },
          { rid: "r2", maxBitrate: 900000, scalabilityMode: "S1T3" },
        ];

        params.codecOptions = { videoGoogleStartBitrate: 1000 };
      }

      const producer = await producerTransport.produce(params);
      console.log("Producer", producer);

      switch (type) {
        case "screen": {
          dispatch(toggleAdminTab(false));
          dispatch(showScreen(stream));
          break;
        }
        case "video": {
          dispatch(addCameraStream({ ancestorId: producer.id, stream, userInfo: { name, _id } }));
          break;
        }
        case "audio": {
          if (isRecording && isSpeaker && audioContext && destination) {
            const sourceNode = audioContext.createMediaStreamSource(stream);
            sourceNode.connect(destination);
          }

          dispatch(addMicrophoneStream({ ancestorId: producer.id, stream, userInfo: { name, _id } }));
          break;
        }
        default:
          break;
      }

      dispatch(addProducer({ producerId: producer.id, producer }));
      dispatch(addProducerLabel({ type, producerId: producer.id }));

      producer.on("trackended", () => {
        console.log("Producer transport trackended");
        dispatch(closeProducer(type));
      });
      producer.on("transportclose", () => {
        console.log("Producer transport close");
        dispatch(closeProducer(type));
      });
    } catch (err) {
      console.log("Produce error:", err);
    }
  };
};

export const closeProducer = (type: ProducerType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { producerLabel, producers } = getState().rtc;

    if (!producerLabel[type]) {
      return console.log("There is no producer for this type " + type);
    }

    const producerId = producerLabel[type]!;
    console.log("Close producer", producerId);

    socket.emit("producerClosed", producerId);

    producers[producerId].close();
    dispatch(deleteProducer(producerId));
    dispatch(deleteProducerLabel(type));

    switch (type) {
      case "screen": {
        const { screen } = getState().rtc;
        if (!screen) return;

        screen.getTracks().forEach((track) => track.stop());
        dispatch(closeScreen());
        break;
      }
      case "video": {
        const cameraStreams = getState().cameraStreams;
        cameraStreams[producerId].stream.getTracks().forEach((track) => track.stop());
        dispatch(deleteCameraStream(producerId));
        break;
      }
      case "audio": {
        const microphoneStreams = getState().microphoneStreams;
        microphoneStreams[producerId].stream.getTracks().forEach((track) => track.stop());
        dispatch(deleteMicrophoneStream(producerId));
        break;
      }
      default:
        break;
    }
  };
};

// Consume

export const consume = (
  producerId: string,
  userInfo: Pick<IUser, "_id" | "name">,
  type: ProducerType,
  audioContext: AudioContext | null,
  destination: MediaStreamAudioDestinationNode | null
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { isSpeaker } = getState().roomHelpers;
    const { isRecording } = getState().room;

    console.log("consume producerId =", producerId);

    try {
      const { consumer, stream } = await dispatch(getConsumeStream(producerId, type));
      if (!consumer || !stream) return;

      dispatch(addConsumer({ consumerId: consumer.id, consumer: consumer }));

      switch (type) {
        case "screen": {
          dispatch(showScreen(stream));
          break;
        }
        case "video": {
          dispatch(addCameraStream({ ancestorId: consumer.id, stream, userInfo }));
          break;
        }
        case "audio": {
          if (isRecording && isSpeaker && audioContext && destination) {
            const sourceNode = audioContext.createMediaStreamSource(stream);
            sourceNode.connect(destination);
          }

          dispatch(addMicrophoneStream({ ancestorId: consumer.id, stream, userInfo }));
          break;
        }
        default:
          break;
      }

      consumer.on("trackended", () => dispatch(removeConsumer(consumer.id, type)));
      consumer.on("transportclose", () => dispatch(removeConsumer(consumer.id, type)));
    } catch (err) {
      console.log("consume failed", err);
    }
  };
};

export const getConsumeStream = (producerId: string, type: ProducerType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { device, consumerTransport } = getState().rtc;
    let consumer, stream;

    if (device && consumerTransport) {
      const { data, isSuccess } = await asyncEmit("consume", {
        rtpCapabilities: device.rtpCapabilities,
        consumerTransportId: consumerTransport.id,
        producerId,
        appData: { type },
      });
      if (!isSuccess) throw new Error("Ошибка потребления потока");

      consumer = await consumerTransport.consume({
        id: data!.id,
        producerId,
        kind: data!.kind,
        rtpParameters: data!.rtpParameters,
      });

      stream = new MediaStream();
      stream.addTrack(consumer.track);
    }

    return { consumer, stream };
  };
};

export const removeConsumer = (consumerId: string, type: ProducerType) => {
  return (dispatch: Dispatch, getState: GetState) => {
    switch (type) {
      case "screen": {
        const { screen } = getState().rtc;
        if (!screen) return;

        screen.getTracks().forEach((track) => track.stop());
        dispatch(closeScreen());
        break;
      }
      case "video": {
        const cameraStreams = getState().cameraStreams;
        cameraStreams[consumerId].stream.getTracks().forEach((track) => track.stop());
        dispatch(deleteCameraStream(consumerId));
        break;
      }
      case "audio": {
        const microphoneStreams = getState().microphoneStreams;
        microphoneStreams[consumerId].stream.getTracks().forEach((track) => track.stop());
        dispatch(deleteMicrophoneStream(consumerId));
        break;
      }
      default:
        break;
    }

    dispatch(deleteConsumer(consumerId));
  };
};

export const stopBroadcast = () => {
  return (dispatch: Dispatch, getState: GetState, { dialogs }: Services) => {
    const { producerLabel } = getState().rtc;

    dialogs.closeAll();
    [...Object.keys(producerLabel)].forEach((label) => dispatch(closeProducer(label as ProducerType)));
  };
};
