import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import UserList from 'components/UserList';
import EmptyBox from 'components/EmptyBox';
import emptyPic from 'images/empty/searching-data.svg';

import { checkAvailable, checkRightsRegardRoster } from 'actions/app';
import { openActiveUsersContextMenu } from 'actions/contextMenu';
import { IUser, Role } from 'types/user';

import { useTypedSelector, useTypedDispatch } from 'store';

import { useGetPeersQuery } from 'actions/room';
import { selectUser } from 'store/reducers/user';

const ActiveTab = () => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(selectUser);

  const { data: peers = {} } = useGetPeersQuery();

  const onClickCtrl = ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: IUser['_id']) => {
    const { left, top } = currentTarget.getBoundingClientRect();

    const targetSizes = {
      clientTargetWidth: currentTarget.clientWidth,
      clientTargetHeight: currentTarget.clientHeight,
    };
    dispatch(openActiveUsersContextMenu(userId, { left, top, targetSizes }));
  };

  const renderContextMenu = (peer: IUser) => {
    const isAvailable = dispatch(checkAvailable(Role.MODERATOR));
    const isUserImportantRoster = dispatch(checkRightsRegardRoster(peer));

    if (isAvailable && isUserImportantRoster && user && peer._id !== user._id) {
      return (
        <div className="user-list__context-menu">
          <IconButton onClick={(e) => onClickCtrl(e, peer._id)}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      );
    }
  };

  const users = [...Object.values(peers)].map(({ user }: { user: IUser }) => user);

  return !!users.length ? (
    <>
      <TextField
        sx={{ mb: 2 }}
        placeholder="Введите имя участника..."
        label="Имя участника"
        size="small"
        variant="outlined"
        fullWidth
      />
      <UserList users={users} renderAction={renderContextMenu} />
    </>
  ) : (
    <EmptyBox severity="info" image={emptyPic} text="Нет участников" />
  );
};

export default ActiveTab;
