import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
// mui
import { Typography, Stack, ToggleButton, ToggleButtonGroup, CircularProgress } from "@mui/material";
// components
import DeviceSelector from "components/DeviceSelector";
// types
import { IDevice } from "types/devices";
import { VideoQuality } from "types/roomHelpers";
// store
import { useTypedDispatch, useTypedSelector } from "store/index";
import { setAudioDevices, setVideoDevices } from "reducers/devices";
import { toggleVideoQuality } from "reducers/roomHelpers";
import { updateTrackQuality } from "store/actions/webinarRoom";
import { selectCameraStreams } from "store/selectors";
import { ConstraintsConfig, getConfig } from "utils/index";

// ----------------------------------------------------------------------

const EquipmentTab: React.FC = () => {
  const dispatch = useTypedDispatch();

  const refVideo = useRef<HTMLVideoElement>(null);
  const { selectedVideoDevice, selectedAudioDevice } = useTypedSelector((state) => state.devices);
  const { videoQuality } = useTypedSelector((state) => state.roomHelpers);
  const { producerLabel } = useTypedSelector((state) => state.rtc);

  const cameraStreams = useTypedSelector(selectCameraStreams);

  const producerId = producerLabel["video"];
  const isEmpty = !selectedVideoDevice && !selectedAudioDevice;

  const cameraStream = useMemo(() => {
    return producerId ? cameraStreams[producerId].stream : null;
  }, [producerId, cameraStreams]);

  const [isLoaded, setIsLoaded] = useState<boolean>(!!producerId);

  const handleChangeQuality = async (event: React.MouseEvent<HTMLElement>, newQuality: VideoQuality | null) => {
    if (!newQuality) return;

    dispatch(toggleVideoQuality(newQuality));

    if (producerId) {
      setIsLoaded(false);
      const videoRef = refVideo.current;

      if (videoRef && videoRef.srcObject) {
        const tracks = (videoRef.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.srcObject = null;
      }
      try {
        await dispatch(updateTrackQuality(newQuality));
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoaded(true);
      }
    }
  };

  const getMedia = async (deviceId: string) => {
    setIsLoaded(false);
    const videoRef = refVideo.current;

    if (videoRef && videoRef.srcObject) {
      const tracks = (videoRef.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.srcObject = null;
    }

    const { mediaConstraints } = getConfig("video", videoQuality, deviceId) as ConstraintsConfig["video"];

    try {
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      if (videoRef) {
        videoRef.srcObject = stream;
      }
    } catch (error) {
      console.error("Ошибка при получении потока:", error);

      if (["OverconstrainedError", "ConstraintNotSatisfiedError"].includes((error as Error).name)) {
        const { mediaConstraints } = getConfig("video", "auto", deviceId) as ConstraintsConfig["video"];

        try {
          const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
          if (videoRef && stream instanceof MediaStream) {
            videoRef.srcObject = stream;
          }
        } catch (error) {
          console.error("Не удалось получить видео с автоматическими размерами:", error);
        }
      } else {
        console.error("Не удалось получить видео:", error);
      }
    } finally {
      setIsLoaded(true);
    }
  };

  const enumerateDevices = async () => {
    const audioDevices: IDevice[] = [];
    const videoDevices: IDevice[] = [];

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      devices.forEach((device) => {
        if (device.kind === "audioinput") audioDevices.push(device);
        if (device.kind === "videoinput") videoDevices.push(device);
      });

      dispatch(setVideoDevices(videoDevices));
      dispatch(setAudioDevices(audioDevices));
    } catch (err) {
      toast.error("Ошибка получения обородувания");
      console.error("Error enumerate devices: ", err);
    }
  };

  useEffect(() => {
    enumerateDevices();
    const videoRef = refVideo.current;

    return () => {
      if (!producerId && videoRef && videoRef.srcObject) {
        const tracks = (videoRef.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.srcObject = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (producerId && refVideo.current) {
      refVideo.current.srcObject = cameraStream;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraStream]);

  useEffect(() => {
    if (!producerId && refVideo.current && selectedVideoDevice) {
      getMedia(selectedVideoDevice.deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoDevice, videoQuality]);

  return (
    <section className="room-broadcast-invite">
      <div className="room-broadcast-invite__scroll-container">
        <div className="room-broadcast-invite__camera-box">
          {!isLoaded && (
            <div className="room-broadcast-invite__loader">
              <CircularProgress />
            </div>
          )}
          <video className="room-broadcast-invite__camera" ref={refVideo} muted autoPlay playsInline />
        </div>
        <p className="room-broadcast-invite__text room-broadcast-invite__text_large">Выберите оборудование</p>
        <DeviceSelector />
      </div>
      {isEmpty && (
        <p className="room-broadcast-invite__text">
          Камера или микрофон не определены.
          <br />
          Пожалуйста, подключите устройства и разрешите их использование в браузере.
        </p>
      )}
      <Stack alignItems="center" justifyContent="center" mt={5}>
        <Typography variant="subtitle1" mb={5}>
          Качество изображения
        </Typography>
        <ToggleButtonGroup
          disabled={!isLoaded}
          color="primary"
          size="small"
          value={videoQuality}
          exclusive
          onChange={handleChangeQuality}
          aria-label="video-quality"
        >
          <ToggleButton value="360">360p</ToggleButton>
          <ToggleButton value="480">480p</ToggleButton>
          <ToggleButton value="720">720p</ToggleButton>
          <ToggleButton value="1080">1080p</ToggleButton>
          <ToggleButton value="auto">авто</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </section>
  );
};

export default EquipmentTab;
