import { useEffect, useState } from 'react';
//
import RoomContext, { IRoomContext } from 'shared/contexts/roomContext';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactElement;
};

const RoomProvider: React.FC<Props> = ({ children }) => {
  const [contextState, setContextState] = useState<IRoomContext['contextState']>({
    imageRef: null,
    whiteboardSnapshot: null,
    recordLayerRef: null,
    audioContext: null,
    destination: null,
  });

  useEffect(() => {
    const audioContext = new AudioContext();
    const destination = audioContext.createMediaStreamDestination();

    setContextState((state) => ({ ...state, audioContext, destination }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RoomContext.Provider value={{ setContextState, contextState }}>{children}</RoomContext.Provider>;
};

export default RoomProvider;
