import { configureStore, isRejectedWithValue, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

import rootReducer from 'reducers';
import core from '../core';

import api from 'utils/api';

const errorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action.', action.type);
    toast.error(action.payload.message);
  }

  return next(action);
};

const middlewares = [api.middleware, errorLogger];

const store = configureStore({
  reducer: {
    ...rootReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: { extraArgument: core }, serializableCheck: false }).concat(middlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type GetState = typeof store.getState;
export type Dispatch = typeof store.dispatch;

export const useTypedDispatch = () => useDispatch<Dispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
