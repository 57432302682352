import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  perPage: number;
  page: number;
  sort: 'ascDate' | 'descDate';
  search: string;
};

const initialState: State = {
  perPage: 10,
  page: 1,
  sort: 'ascDate',
  search: '',
};

export const recordSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setSort(state, { payload }: PayloadAction<State['sort']>) {
      state.sort = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
  },
});

export const { setPage, setSearch, setSort } = recordSlice.actions;

export default recordSlice.reducer;
