import "./index.less";
import { useEffect, useRef, useState, useContext } from "react";
import { toast } from "react-toastify";
// mui
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  ToggleButton,
  Button,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
// components
import DeviceSelector from "components/DeviceSelector";
// types
import { IDevice } from "types/devices";
import { VideoQuality } from "types/roomHelpers";
// store
import { startCamera, startAudio } from "actions/webinarRoom";
import { useTypedDispatch, useTypedSelector } from "store/index";
import { toggleVideoQuality } from "reducers/roomHelpers";
import { setAudioDevices, setVideoDevices } from "reducers/devices";
// context
import RoomContext from "shared/contexts/roomContext";
import { ConstraintsConfig, getConfig } from "utils/index";

// ----------------------------------------------------------------------

type Props = {
  close: () => void;
  defaultIsMicroOnly?: boolean;
};

const Body: React.FC<Props> = ({ close, defaultIsMicroOnly = false }) => {
  const dispatch = useTypedDispatch();
  const refVideo = useRef<HTMLVideoElement>(null);

  const { contextState: { audioContext = null, destination = null } = {} } = useContext(RoomContext) || {};

  const { selectedVideoDevice, selectedAudioDevice } = useTypedSelector((state) => state.devices);
  const { isBroadcastAllowed, isSpeaker, videoQuality } = useTypedSelector((state) => state.roomHelpers);
  const { producerLabel } = useTypedSelector((state) => state.rtc);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isMicroOnly, setIsMicroOnly] = useState<boolean>(defaultIsMicroOnly);

  const isCameraStarted = !!producerLabel["video"];
  const isAudioStarted = !!producerLabel["audio"];

  const isConnectAvailable =
    isSpeaker || isBroadcastAllowed
      ? isMicroOnly
        ? selectedAudioDevice
        : selectedVideoDevice && selectedAudioDevice
      : false;

  const getEmptyEquipmentPhrase = () => {
    if (isMicroOnly) {
      return selectedAudioDevice
        ? null
        : `Микрофон не обнаружен\nПожалуйста, подключите устройство и разрешите их использование в браузере.`;
    }

    return selectedVideoDevice && selectedAudioDevice
      ? null
      : `Микрофон или камера не обнаружены\nПожалуйста, подключите устройства и разрешите их использование в браузере.`;
  };

  const emptyEquipmentPhrase = getEmptyEquipmentPhrase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMicroOnly(event.target.checked);
  };

  const handleChangeQuality = (event: React.MouseEvent<HTMLElement>, newAlignment: VideoQuality | null) => {
    if (newAlignment !== null) {
      dispatch(toggleVideoQuality(newAlignment));
    }
  };

  const getMedia = async (deviceId: string) => {
    setIsLoaded(false);
    const videoRef = refVideo.current;

    if (videoRef && videoRef.srcObject) {
      const tracks = (videoRef.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.srcObject = null;
    }

    const { mediaConstraints } = getConfig("video", videoQuality, deviceId) as ConstraintsConfig["video"];

    try {
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      if (videoRef) {
        videoRef.srcObject = stream;
      }
    } catch (error) {
      console.error("Ошибка при получении потока:", error);

      if (["OverconstrainedError", "ConstraintNotSatisfiedError"].includes((error as Error).name)) {
        const { mediaConstraints } = getConfig("video", "auto", deviceId) as ConstraintsConfig["video"];

        try {
          const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
          if (videoRef && stream instanceof MediaStream) {
            videoRef.srcObject = stream;
          }
        } catch (error) {
          console.error("Не удалось получить видео с автоматическими размерами:", error);
        }
      } else {
        console.error("Не удалось получить видео:", error);
      }
    } finally {
      setIsLoaded(true);
    }
  };

  const enumerateDevices = async () => {
    const audioDevices: IDevice[] = [];
    const videoDevices: IDevice[] = [];

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      devices.forEach((device) => {
        if (device.kind === "audioinput") audioDevices.push(device);
        if (device.kind === "videoinput") videoDevices.push(device);
      });

      dispatch(setVideoDevices(videoDevices));
      dispatch(setAudioDevices(audioDevices));
    } catch (err) {
      toast.error("Ошибка получения обородувания");
      console.error("Error enumerate devices: ", err);
    }
  };

  useEffect(() => {
    if (!isMicroOnly && selectedVideoDevice) {
      getMedia(selectedVideoDevice.deviceId);
    }

    if (isMicroOnly && refVideo.current && refVideo.current.srcObject) {
      const tracks = (refVideo.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      refVideo.current.srcObject = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicroOnly, selectedVideoDevice, videoQuality]);

  useEffect(() => {
    enumerateDevices();
    const videoRef = refVideo.current;

    return () => {
      if (videoRef && videoRef.srcObject) {
        const tracks = (videoRef.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.srcObject = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (!isCameraStarted && !isMicroOnly) {
      dispatch(startCamera());
    }

    if (!isAudioStarted) {
      dispatch(startAudio(audioContext, destination));
    }

    close();
  };

  return (
    <section className="room-broadcast-invite">
      <div className="room-broadcast-invite__scroll-container">
        <div className="room-broadcast-invite__camera-box">
          {!isMicroOnly && !isLoaded && (
            <div className="room-broadcast-invite__loader">
              <CircularProgress />
            </div>
          )}
          {isMicroOnly && <div className="room-broadcast-invite__loader">Используется только микрофон</div>}
          <video className="room-broadcast-invite__camera" ref={refVideo} muted autoPlay playsInline />
        </div>
        <p className="room-broadcast-invite__text room-broadcast-invite__text_large">Выберите оборудование</p>
        <DeviceSelector isMicroOnly={isMicroOnly} />
        {emptyEquipmentPhrase && <p className="room-broadcast-invite__text">{emptyEquipmentPhrase}</p>}
        <Stack alignItems="center" justifyContent="center" mt={5}>
          <Typography variant="subtitle1" mb={5}>
            Качество изображения
          </Typography>
          <ToggleButtonGroup
            disabled={!isLoaded || isMicroOnly}
            color="primary"
            size="small"
            value={videoQuality}
            exclusive
            onChange={handleChangeQuality}
            aria-label="video-quality"
          >
            <ToggleButton value="360">360p</ToggleButton>
            <ToggleButton value="480">480p</ToggleButton>
            <ToggleButton value="720">720p</ToggleButton>
            <ToggleButton value="1080">1080p</ToggleButton>
            <ToggleButton value="auto">авто</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack alignItems="center" justifyContent="center" mt={5}>
          <FormControlLabel
            control={
              <Checkbox checked={isMicroOnly} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
            }
            label="Использовать только микрофон"
          />
        </Stack>
      </div>

      <Stack justifyContent="center" alignItems="center" spacing={2} direction="row" mt={5}>
        {isConnectAvailable && (
          <Button disableElevation variant="contained" onClick={onSubmit}>
            Подключиться
          </Button>
        )}
        <Button variant="outlined" onClick={close}>
          Только просмотр
        </Button>
      </Stack>
    </section>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "roomBroadcastInvite",
  Title: "Выход в эфир",
  Footer: false,
  Body,
};
