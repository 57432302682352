import './index.less';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import ItemActions from './Actions';
import { declOfNum } from 'utils/index';

import { openImageView } from 'actions/dialogs';

// ui
import LazyImage from 'shared/ui/LazyImage';

const ItemImage = ({ item, maxSelected, isAction = false }) => {
  const dispatch = useDispatch();
  const { selectedFiles } = useSelector((state) => state.popupFileSelector);
  const { _id: itemId, previewUrls, name, slides, path } = item;

  const isPresentation = Array.isArray(slides) && slides.length > 1;
  const isSelected = selectedFiles.some(({ _id }) => _id === itemId);
  const action = () => dispatch(openImageView(isPresentation ? slides : path));

  return (
    <div className="dialog-file-selector-item">
      <LazyImage src={previewUrls?.small} thumb={previewUrls?.thumb} alt={`Изображение ${name}`} />
      {!!slides.length && (
        <>
          <div className={cn('dialog-file-selector-item__name', { hidden: isSelected })}>{name}</div>
          <div className={cn('dialog-file-selector-item__info', { hidden: isSelected })}>
            {`${slides.length} ${declOfNum(slides.length, ['слайд', 'слайда', 'слайдов'])}`}
          </div>
        </>
      )}
      <ItemActions
        isAction={isAction}
        maxSelected={maxSelected}
        isSelected={isSelected}
        item={item}
        mainBtn={{ icon: ZoomOutMapIcon, action }}
      />
    </div>
  );
};

export default ItemImage;
