import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFile } from 'types/file';

type PlayerState = {
  isOpen: boolean;
  isPlaying: boolean;
  tracks: IFile[];
  trackIndex: number;
  volume: number;
  lastVolume: number;
};

const initialState: PlayerState = {
  isOpen: false,
  isPlaying: false,
  tracks: [],
  trackIndex: -1,
  volume: 0.1,
  lastVolume: 0.1,
};

export const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    onClose(state) {
      state.trackIndex = -1;
      state.tracks = [];
      state.isOpen = false;
    },
    setIsPlaying(state, { payload }: PayloadAction<boolean>) {
      state.isPlaying = payload;
    },
    mute(state) {
      state.volume = 0;
    },
    setVolume(state, { payload }: PayloadAction<number>) {
      state.volume = payload;
      state.lastVolume = payload;
    },
    setTrackIndex(state, { payload }: PayloadAction<number>) {
      state.trackIndex = payload;
    },
    addTrack(state, { payload }: PayloadAction<IFile>) {
      if (!state.tracks.some(({ _id }) => _id === payload._id)) {
        state.tracks.push(payload);
      }

      state.trackIndex = state.tracks.map(({ _id }) => _id).indexOf(payload._id);
      state.isOpen = true;
    },
  },
});

export const { onClose, setIsPlaying, mute, setVolume, setTrackIndex, addTrack } = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;
