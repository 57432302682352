import LoadingButton from '@mui/lab/LoadingButton';
import { DialogProps } from './Dialog';

type Props = {
  isLoading: boolean;
  onSuccess: () => Promise<void>;
  successButton?: DialogProps['successButton'];
};

const Success: React.FC<Props> = ({ successButton, isLoading, onSuccess }) => {
  const { title = 'Сохранить', disabled = false, hint, danger = false } = successButton || {};

  return (
    <LoadingButton
      disableElevation
      color={danger ? 'error' : 'primary'}
      variant="contained"
      onClick={onSuccess}
      loading={isLoading}
      disabled={disabled}
      title={hint}
    >
      {title}
    </LoadingButton>
  );
};

export default Success;
