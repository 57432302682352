import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { LocalActivity, Block } from '@mui/icons-material';

import ActiveTab from './ActiveTab';
import BlockedTab from './BlockedTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Box sx={{ p: { xxxs: 3, sm: 6 } }} hidden={value !== index}>
      {value === index && children}
    </Box>
  );
};

const Body = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box width="100%" className="room-rosters-dialog">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
          <Tab sx={{ minHeight: '50px' }} icon={<LocalActivity />} iconPosition="start" label="Активные" />
          <Tab sx={{ minHeight: '50px' }} icon={<Block />} iconPosition="start" label="Заблокированные" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ActiveTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BlockedTab />
      </TabPanel>
    </Box>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'roomRosters',
  Title: 'Список участников',
  Footer: false,
  Body,
};
