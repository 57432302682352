import './index.less';
import { useState } from 'react';
import { sourceWithBaseUrl } from 'utils/index';
import CachedImage from 'components/CachedImage';

type Props = {
  position?: React.CSSProperties['objectPosition'];
  objectFit?: React.CSSProperties['objectFit'];
  src?: string;
  thumb?: string;
  alt?: string;
};

const LazyImageItem: React.FC<Props> = ({ position = 'center center', alt = '', thumb, src, objectFit = 'cover' }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <CachedImage
        className="lazy-image-item lazy-image-item_thumb"
        alt={alt}
        url={sourceWithBaseUrl(thumb)}
        style={{ visibility: isLoaded ? 'hidden' : 'visible', objectFit }}
      />
      <CachedImage
        onLoad={() => setIsLoaded(true)}
        className="lazy-image-item"
        style={{ opacity: isLoaded ? 1 : 0, objectPosition: position, objectFit }}
        alt={alt}
        url={sourceWithBaseUrl(src)}
      />
    </>
  );
};
export default LazyImageItem;
