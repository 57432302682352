import { DialogProps } from './Dialog';

const Message: React.FC<DialogProps> = ({ message }) => {
  if (!message || typeof message !== `string`) return null;

  return (
    <div className="dialog__message">
      {message.split(`\n`).map((x, i) => (
        <p key={i}>{x}</p>
      ))}
    </div>
  );
};

export default Message;
