import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'types/user';

export type MicrophoneStream = {
  stream: MediaStream;
  userInfo: Pick<IUser, '_id' | 'name'>;
};

type MicrophoneStreams = {
  [key: string]: MicrophoneStream;
};

const initialState: MicrophoneStreams = {};

export const microphoneStreamsSlice = createSlice({
  name: 'microphoneStreams',
  initialState,
  reducers: {
    addMicrophoneStream(
      state,
      {
        payload: { ancestorId, stream, userInfo },
      }: PayloadAction<{ ancestorId: string; stream: MediaStream; userInfo: Pick<IUser, '_id' | 'name'> }>
    ) {
      if (!state[ancestorId]) {
        state[ancestorId] = { stream, userInfo };
      }
    },
    deleteMicrophoneStream(state, { payload }: PayloadAction<string>) {
      if (state[payload]) delete state[payload];
    },
  },
});

export const { addMicrophoneStream, deleteMicrophoneStream } = microphoneStreamsSlice.actions;

export default microphoneStreamsSlice.reducer;
