import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';

import { setVolume, mute } from 'reducers/audioPlayer';
import { useTypedDispatch, useTypedSelector } from 'store/index';

const mainIconColor = '#fff';
type Props = { onClose: () => void };

const Actions: React.FC<Props> = ({ onClose }) => {
  const dispatch = useTypedDispatch();
  const { volume, lastVolume } = useTypedSelector((state) => state.audioPlayer);

  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (event: Event, newValue: number | number[]) => dispatch(setVolume(newValue as number));
  const handleChangeVolume = () => dispatch(volume === 0 ? setVolume(lastVolume) : mute());
  const toggleVisible = (value: boolean) => () => setIsVisible(value);

  return (
    <Stack direction="row" alignItems="center">
      <div onMouseLeave={toggleVisible(false)} onMouseOver={toggleVisible(true)} className="audio-player__volume">
        <CSSTransition
          in={isVisible}
          classNames="audio-player__volume-container"
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <div className="audio-player__volume-container">
            <div className="audio-player__volume-inner">
              <Slider
                sx={{
                  color: '#fff',
                  '& .MuiSlider-track': { border: 'none' },
                  '& .MuiSlider-thumb': {
                    width: 24,
                    height: 24,
                    backgroundColor: '#fff',
                    '&:before': { boxShadow: '0 4px 8px rgba(0,0,0,0.4)' },
                    '&:hover, &.Mui-focusVisible, &.Mui-active': { boxShadow: 'none' },
                  },
                }}
                value={volume}
                step={0.01}
                min={0}
                max={1}
                onChange={handleChange}
                orientation="vertical"
              />
            </div>
            <div className="audio-player__volume-holder" />
          </div>
        </CSSTransition>
        <IconButton onClick={handleChangeVolume}>
          {!volume ? <VolumeOffIcon htmlColor={mainIconColor} /> : <VolumeUpIcon htmlColor={mainIconColor} />}
        </IconButton>
      </div>
      <IconButton onClick={onClose}>
        <CloseIcon htmlColor={mainIconColor} />
      </IconButton>
    </Stack>
  );
};

export default Actions;
