import React, { useEffect } from 'react';

type IntersectionFunc = {
  target: React.MutableRefObject<Element | null>;
  onIntersect: IntersectionObserverCallback;
  threshold?: number;
  rootMargin?: string;
};

const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0.2,
  rootMargin = '0px 0px 0px 0px',
}: IntersectionFunc) => {
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, { rootMargin, threshold });
    if (!target.current) return;

    const current = target.current;
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  });
};

export default useIntersectionObserver;
