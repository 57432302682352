import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { roomHelpersState, VideoQuality } from "types/roomHelpers";

const initialState: roomHelpersState = {
  isConnected: false,
  isSpeaker: false,
  isDrawing: false,
  isAdminTabOpen: false,
  chatHeightIndex: 0,
  majorSplit: [70, 30],
  minorSplit: [30, 70],
  isBroadcastAllowed: false,
  videoQuality: "auto",
};

export const roomSlice = createSlice({
  name: "roomHelpers",
  initialState,
  reducers: {
    setIsConnected(state, { payload }: { payload: boolean }) {
      state.isConnected = payload;
    },
    setIsSpeaker(state, { payload }: { payload: boolean }) {
      state.isSpeaker = payload;
    },
    setIsBroadcastAllowed(state, { payload }: { payload: boolean }) {
      state.isBroadcastAllowed = payload;
    },
    setIsDrawing(state, { payload }: { payload: boolean }) {
      state.isDrawing = payload;
    },
    toggleAdminTab(state, { payload }: { payload: boolean }) {
      state.isAdminTabOpen = payload;
    },
    setChatHeightIndex(state, { payload }: { payload: 0 | 1 | 2 }) {
      state.chatHeightIndex = payload;
    },
    resizeMajorSplit(state, { payload }: { payload: [number, number] }) {
      state.majorSplit = payload;
    },
    resizeMinorSplit(state, { payload }: { payload: [number, number] }) {
      state.minorSplit = payload;
    },
    toggleVideoQuality(state, { payload }: PayloadAction<VideoQuality>) {
      state.videoQuality = payload;
    },
  },
});

export const {
  setIsConnected,
  setIsSpeaker,
  setIsDrawing,
  toggleAdminTab,
  setChatHeightIndex,
  resizeMajorSplit,
  resizeMinorSplit,
  setIsBroadcastAllowed,
  toggleVideoQuality,
} = roomSlice.actions;

export default roomSlice.reducer;
