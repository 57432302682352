import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'all',
  sort: 'startDateAsc',
  search: '',
  perPage: 12,
  page: 1,
};

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setPerPage(state, action) {
      state.perPage = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
  },
});

export const { setStatus, setSort, setPage, setPerPage, setSearch } = webinarsSlice.actions;

export default webinarsSlice.reducer;
