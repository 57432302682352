import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as mediasoupTypes, types } from 'mediasoup-client';

type Rtc = {
  producerLabel: Partial<Record<'audio' | 'video' | 'screen', string>>;
  consumers: { [key: string]: types.Consumer };
  producers: { [key: string]: types.Producer };
  recordProducers: types.Producer[];
  recordStreams: MediaStream[];
  device: mediasoupTypes.Device | null;
  producerTransport: mediasoupTypes.Transport | null;
  consumerTransport: mediasoupTypes.Transport | null;
  screen: MediaStream | null;
};

const initialState: Rtc = {
  producerLabel: {},
  consumers: {},
  producers: {},
  recordProducers: [],
  recordStreams: [],
  device: null,
  producerTransport: null,
  consumerTransport: null,
  screen: null,
};

export const rtcSlice = createSlice({
  name: 'rtc',
  initialState: initialState,
  reducers: {
    // ------------------
    // ----- RECORD -----
    // ------------------

    addRecordProducer(state, { payload }: PayloadAction<mediasoupTypes.Producer>) {
      state.recordProducers.push(payload);
    },
    deleteRecordProducers(state) {
      state.recordProducers = [];
    },
    addRecordStream(state, { payload }: PayloadAction<MediaStream>) {
      state.recordStreams.push(payload);
    },
    deleteRecordStreams(state) {
      state.recordStreams = [];
    },
    //
    // -----
    //

    addProducer(
      state,
      { payload: { producerId, producer } }: PayloadAction<{ producerId: string; producer: mediasoupTypes.Producer }>
    ) {
      state.producers[producerId] = producer;
    },
    deleteProducer(state, { payload }) {
      if (state.producers[payload]) delete state.producers[payload];
    },
    addConsumer(state, { payload: { consumerId, consumer } }) {
      state.consumers[consumerId] = consumer;
    },
    deleteConsumer(state, { payload }) {
      if (state.consumers[payload]) delete state.consumers[payload];
    },
    addProducerLabel(
      state,
      { payload: { type, producerId } }: PayloadAction<{ type: 'video' | 'audio' | 'screen'; producerId: string }>
    ) {
      if (!state.producerLabel[type]) {
        state.producerLabel[type] = producerId;
      }
    },
    deleteProducerLabel(state, { payload }: PayloadAction<'video' | 'audio' | 'screen'>) {
      if (state.producerLabel[payload]) delete state.producerLabel[payload];
    },
    setDevice(state, { payload }) {
      state.device = payload;
    },
    setProducerTransport(state, { payload }: PayloadAction<mediasoupTypes.Transport>) {
      state.producerTransport = payload;
    },
    setConsumerTransport(state, { payload }: PayloadAction<mediasoupTypes.Transport>) {
      state.consumerTransport = payload;
    },
    showScreen(state, { payload }: PayloadAction<MediaStream>) {
      state.screen = payload;
    },
    closeScreen(state) {
      state.screen = null;
    },
  },
});

export const {
  // record
  addRecordProducer,
  deleteRecordProducers,
  addRecordStream,
  deleteRecordStreams,
  //
  addProducer,
  deleteProducer,
  addConsumer,
  deleteConsumer,
  addProducerLabel,
  deleteProducerLabel,
  setDevice,
  setProducerTransport,
  setConsumerTransport,
  showScreen,
  closeScreen,
} = rtcSlice.actions;

export default rtcSlice.reducer;
