import user from "./user";
import users from "./users";
import file from "./file";
import webinar from "./webinar";
import webinars from "./webinars";
import uploader from "./uploader";
import popupFileSelector from "./popupFileSelector";
import audioPlayer from "./audioPlayer";
import dialogs from "./dialogs";
import devices from "./devices";
import contextMenu from "./contextMenu";
import cameraStreams from "./cameraStreams";
import microphoneStreams from "./microphoneStreams";
import rtc from "./rtc";
import whiteboard from "./whiteboard";
import room from "./room";
import roomHelpers from "./roomHelpers";
import record from "./record";
import modal from "pages/Admin/widgets/ModalManager/model";
//
import { slideSelectorModel } from "entities/slide-selector";

const rootReducer = {
  user,
  users,
  file,
  webinar,
  webinars,
  uploader,
  popupFileSelector,
  audioPlayer,
  dialogs,
  devices,
  contextMenu,
  cameraStreams,
  microphoneStreams,
  rtc,
  whiteboard,
  room,
  roomHelpers,
  record,
  modal,
  //
  slideSelector: slideSelectorModel.default,
};

export default rootReducer;
