import { createSlice } from "@reduxjs/toolkit";

type SlideSelectorState = {
  isOpen: boolean;
};

const initialState: SlideSelectorState = {
  isOpen: false,
};

export const slideSelectorSlice = createSlice({
  name: "slideSelector",
  initialState,
  reducers: {
    toggleOpen(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleOpen } = slideSelectorSlice.actions;

export default slideSelectorSlice.reducer;
