import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import socket from 'utils/socket';
import { useTypedSelector } from 'store';

const StyledToggleButton = styled(ToggleButton)(() => ({ padding: '4px 8px' }));

const ChatTab = () => {
  const { isChatAvailable } = useTypedSelector((state) => state.room);

  const handleChange = (event: React.MouseEvent<HTMLElement>, state: boolean) => {
    socket.emit('chat:switch-chatMode', state);
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="body1">Кто может писать в чат</Typography>
      <ToggleButtonGroup color="primary" value={isChatAvailable} exclusive onChange={handleChange}>
        <StyledToggleButton value={true}>все</StyledToggleButton>
        <StyledToggleButton value={false}>организаторы</StyledToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ChatTab;
