import Konva from 'konva';
import { v4 as uuidv4 } from 'uuid';

import { addFigure, changeFigure, setTool, selectShape, deleteFigure, clear } from 'reducers/whiteboard';
import { CanvasLine, CanvasRectCirc } from 'types/whiteboard';
import { Dispatch, GetState } from 'store';
import socket from 'utils/socket';

export const removeListeners = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { stage } = getState().whiteboard;
    if (!stage) return;

    const events = [
      'mouseup.brush',
      'mousemove.brush',
      'mousedown.brush',
      'touchstart.brush',
      'touchmove.brush',
      'touchend.brush',
      'mousedown.rect',
      'touchstart.rect',
      'mousedown.circ',
      'touchstart.circ',
    ];

    for (const event of events) {
      stage.off(event);
    }
  };
};

export const onKeydown = (e: React.KeyboardEvent) => (dispatch: Dispatch, getState: GetState) => {
  const { selectedShape } = getState().whiteboard;
  if (!selectedShape) return;

  if (e.key === 'Escape') dispatch(selectShape(null));
  if (e.key === 'Delete') {
    dispatch(deleteFigure(selectedShape));
    socket.emit('whiteboard:delete-figure', selectedShape);
  }
};

export const clearWhiteboard = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch(clear());
  socket.emit('whiteboard:clear');
};

// line
export const addLine = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { user } = getState().user;
    const { stage, layer } = getState().whiteboard;

    if (!layer || !stage || !user) return;

    let lastLine: CanvasLine;
    let isPaint: boolean = false;
    let lineKonva: Konva.Line;

    const onStart = () => {
      isPaint = true;
      const pos = stage.getRelativePointerPosition();
      const { stroke, strokeWidth } = getState().whiteboard;

      lastLine = {
        author: user._id,
        id: uuidv4(),
        tool: 'brush',
        stroke,
        globalCompositeOperation: 'source-over',
        strokeWidth,
        points: [{ x: pos.x, y: pos.y }],
      };

      lineKonva = new Konva.Line({
        lineJoin: 'round',
        lineCap: 'round',
        tension: 0.5,
        stroke,
        strokeWidth,
        points: [pos.x, pos.y],
      });
      layer.add(lineKonva);
    };

    const onMove = () => {
      if (!isPaint) return;
      const pos = stage.getRelativePointerPosition();
      lastLine.points.push({ x: pos.x, y: pos.y });

      const newPoints = lineKonva.points().concat([pos.x, pos.y]);
      lineKonva.points(newPoints);
      layer.batchDraw();
    };

    const onEnd = () => {
      isPaint = false;
      dispatch(addFigure(lastLine));
      socket.emit('whiteboard:add-figure', lastLine);

      layer.removeChildren();
    };

    stage.on('mousedown.brush touchstart.brush', onStart);
    stage.on('mousemove.brush touchmove.brush', onMove);
    stage.on('mouseup.brush touchend.brush', onEnd);
  };
};

// eraser
export const addEraserLine = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { user } = getState().user;
    const { stage } = getState().whiteboard;

    if (!stage || !user) return;

    let lastLine: CanvasLine;
    let isPaint: boolean = false;

    const onStart = () => {
      isPaint = true;
      const pos = stage.getRelativePointerPosition();
      const { stroke, strokeWidth } = getState().whiteboard;

      lastLine = {
        author: user._id,
        id: uuidv4(),
        tool: 'brush',
        stroke,
        globalCompositeOperation: 'destination-out',
        strokeWidth,
        points: [{ x: pos.x, y: pos.y }],
      };

      dispatch(addFigure(lastLine));
    };

    const onMove = () => {
      if (!isPaint) return;
      const pos = stage.getRelativePointerPosition();
      lastLine = { ...lastLine, points: [...lastLine.points, { x: pos.x, y: pos.y }] };
      dispatch(changeFigure(lastLine));
    };

    const onEnd = () => {
      isPaint = false;
      socket.emit('whiteboard:add-figure', lastLine);
    };

    stage.on('mousedown.brush touchstart.brush', onStart);
    stage.on('mousemove.brush touchmove.brush', onMove);
    stage.on('mouseup.brush touchend.brush', onEnd);
  };
};

// rect
export const addRectangle = () => (dispatch: Dispatch, getState: GetState) => {
  const { stage } = getState().whiteboard;
  const { user } = getState().user;

  if (!stage || !user) return;

  const onSelectPos = () => {
    const pos = stage.getRelativePointerPosition();
    const { stroke, strokeWidth } = getState().whiteboard;
    const { width } = stage.getAttrs();

    const id = uuidv4();
    const options = { author: user._id, width: width / 5, height: width / 5, x: pos.x, y: pos.y, id };
    const figure: CanvasRectCirc = { tool: 'rect', fill: stroke, stroke, strokeWidth, ...options };

    dispatch(addFigure(figure));
    socket.emit('whiteboard:add-figure', figure);

    dispatch(setTool(null));
    dispatch(selectShape(id));
  };

  stage.on('mousedown.rect touchstart.rect', onSelectPos);
};

// circle
export const addCircle = () => (dispatch: Dispatch, getState: GetState) => {
  const { stage } = getState().whiteboard;
  const { user } = getState().user;

  if (!stage || !user) return;

  const onSelectPos = () => {
    const pos = stage.getRelativePointerPosition();
    const { stroke, strokeWidth } = getState().whiteboard;
    const { width } = stage.getAttrs();

    const id = uuidv4();
    const options = { author: user._id, width: width / 5, height: width / 5, x: pos.x, y: pos.y, id };
    const figure: CanvasRectCirc = { tool: 'circ', fill: stroke, stroke, strokeWidth, ...options };

    dispatch(addFigure(figure));
    socket.emit('whiteboard:add-figure', figure);

    dispatch(setTool(null));
    dispatch(selectShape(id));
  };

  stage.on('mousedown.circ touchstart.circ', onSelectPos);
};
