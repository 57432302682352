import { v4 as uuidv4 } from 'uuid';
import { Save, Cancel, DoNotTouch, Camera, LayersClear, AddToPhotos, LockOpen, Lock } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

import { setHand, allowBroadcast, forbidBroadcast } from 'actions/webinarRoom';
import { userBlockConfirm, userUnblockConfirm } from './dialogs';
import { clearWhiteboard } from 'actions/whiteboard';

import { Services } from 'core';
import { Dispatch, GetState } from 'store';
import { IUser } from 'types/user';
import { IFile } from 'types/file';
import { asyncEmit } from 'utils/socket';

type contextPos = {
  left: number;
  top: number;
  targetSizes: {
    clientTargetWidth: number;
    clientTargetHeight: number;
  };
  offset?: number;
};

type menuElement = {
  id: number;
  name: string;
  icon?: (props: SvgIconProps) => JSX.Element;
  disabled?: boolean;
  danger?: boolean;
};

export const closeContextMenu = () => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu }: Services) => contextMenu.close();
};

export const openCanvasSettings = ({ left, top, targetSizes, offset }: contextPos) => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu }: Services) => {
    const { stage } = getState().whiteboard;

    const items: menuElement[] = [
      { id: 1, name: 'Сохранить рисунок', icon: Save },
      { id: 2, name: 'Очистить холст', icon: LayersClear, danger: true },
    ];

    return contextMenu.open({
      onSelect: ({ id }: { id: number }) => {
        switch (id) {
          case 1: {
            if (!stage) return;

            const dataUrl = stage.toDataURL();
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = uuidv4() + '.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            break;
          }
          case 2:
            return dispatch(clearWhiteboard());
          default:
            return contextMenu.close();
        }
      },
      items,
      top,
      left,
      targetSizes,
      offset,
    });
  };
};

export const openUsersHandsUpContextMenu = (userId: IUser['_id'], { left, top, targetSizes }: contextPos) => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu }: Services) => {
    const { peers } = getState().webinar;
    const { isBroadcastAllowed } = peers[userId] || {};

    const countBroadcastAllows = Object.values(peers).reduce((acc, curr) => {
      if (curr.isBroadcastAllowed) acc++;
      return acc;
    }, 0);

    const connectEl = { id: 2, name: 'Пригласить в эфир', icon: Camera, disabled: countBroadcastAllows >= 4 };
    const disableEl = { id: 3, name: 'Отключить', icon: Cancel, danger: true };

    const items: menuElement[] = [{ id: 1, name: 'Опустить руку', icon: DoNotTouch }];
    items.push(isBroadcastAllowed ? disableEl : connectEl);

    return contextMenu.open({
      onSelect: ({ id }: { id: number }) => {
        switch (id) {
          case 1:
            return dispatch(setHand(false, userId));
          case 2:
            return dispatch(allowBroadcast(userId));
          case 3:
            return dispatch(forbidBroadcast(userId));
          default:
            return contextMenu.close();
        }
      },
      items,
      top,
      left,
      targetSizes,
    });
  };
};

export const openActiveUsersContextMenu = (userId: IUser['_id'], { left, top, targetSizes }: contextPos) => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu }: Services) => {
    const { peers } = getState().webinar;
    const { isBroadcastAllowed } = peers[userId] || {};

    const countBroadcastAllows = Object.values(peers).reduce((acc, curr) => {
      if (curr.isBroadcastAllowed) acc++;
      return acc;
    }, 0);

    const connectEl = { id: 2, name: 'Пригласить в эфир', icon: Camera, disabled: countBroadcastAllows >= 4 };
    const disableEl = { id: 3, name: 'Отключить', icon: Cancel, danger: true };

    const items: menuElement[] = [{ id: 1, name: 'Заблокировать', icon: Lock }];
    items.push(isBroadcastAllowed ? disableEl : connectEl);

    return contextMenu.open({
      onSelect: ({ id }: { id: number }) => {
        switch (id) {
          case 1:
            return dispatch(userBlockConfirm(userId));
          case 2: {
            dispatch(setHand(false, userId));
            dispatch(allowBroadcast(userId));

            break;
          }
          case 3:
            return dispatch(forbidBroadcast(userId));
          default:
            return contextMenu.close();
        }
      },
      items,
      top,
      left,
      targetSizes,
    });
  };
};

export const openBlockedUsersContextMenu = (userId: IUser['_id'], { left, top, targetSizes }: contextPos) => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu }: Services) => {
    const items: menuElement[] = [{ id: 1, name: 'Разблокировать', icon: LockOpen }];

    return contextMenu.open({
      onSelect: ({ id }: { id: number }) => {
        switch (id) {
          case 1:
            return dispatch(userUnblockConfirm(userId));
          default:
            return contextMenu.close();
        }
      },
      items,
      top,
      left,
      targetSizes,
    });
  };
};

const socketCreateTab = async (materialId: IFile['_id'], { contextMenu, dialogs }: Services) => {
  const { isSuccess } = await asyncEmit('room:create-tabs', [materialId]);

  if (!isSuccess) {
    contextMenu.close();
    dialogs.closeAll();
  }
};

export const openRoomMaterialsContextMenu = (materialId: IFile['_id'], { left, top, targetSizes }: contextPos) => {
  return (dispatch: Dispatch, getState: GetState, { contextMenu, dialogs }: Services) => {
    const items: menuElement[] = [{ id: 1, name: `Добавить`, icon: AddToPhotos }];

    return contextMenu.open({
      onSelect: ({ id }: { id: number }) => {
        switch (id) {
          case 1: {
            socketCreateTab(materialId, { contextMenu, dialogs });
            break;
          }
          default:
            return contextMenu.close();
        }
      },
      items,
      top,
      left,
      targetSizes,
    });
  };
};
