import './index.less';

import Avatar from 'components/Avatar';
import RoleTag from 'components/RoleTag';
import { Role } from 'types/user';

const Item = ({ user = {}, renderAction = () => null }) => {
  const { name, country, city, role } = user;

  const renderTag = () => {
    if (role >= Role.MODERATOR) {
      return <RoleTag user={user} size="small" />;
    }

    return null;
  };

  return (
    <li className="user-list__user">
      <Avatar user={user} />
      <div className="user-list__data">
        <div className="user-list__title">
          {name}
          <span className="user-list__tag">{renderTag()}</span>
        </div>
        <div className="user-list__subtitle">{`${country}, ${city}`}</div>
      </div>
      {renderAction(user)}
    </li>
  );
};

export default Item;
