import './index.less';
import { useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';

import UserList from 'components/UserList';

import { openUsersHandsUpContextMenu } from 'actions/contextMenu';
import { lowerAllHands } from 'actions/webinarRoom';
import { useTypedDispatch, useTypedSelector } from 'store/index';
import { IUser } from 'types/user';
import { getPeersHandsUp } from 'store/reducers/webinar';

type Props = {
  close: () => void;
};

const Body: React.FC<Props> = ({ close }) => {
  const dispatch = useTypedDispatch();
  const peersHandsUp = useTypedSelector(getPeersHandsUp);

  useEffect(() => {
    if (!peersHandsUp.length) close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peersHandsUp]);

  const onClickCtrl = ({ currentTarget }: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: IUser['_id']) => {
    const { left, top } = currentTarget.getBoundingClientRect();

    const targetSizes = {
      clientTargetWidth: currentTarget.clientWidth,
      clientTargetHeight: currentTarget.clientHeight,
    };
    dispatch(openUsersHandsUpContextMenu(userId, { left, top, targetSizes }));
  };

  const renderContextMenu = (user: IUser) => {
    return (
      <div className="user-list__context-menu">
        <IconButton onClick={(e) => onClickCtrl(e, user._id)} size="small">
          <MoreHorizIcon />
        </IconButton>
      </div>
    );
  };

  const onClick = () => dispatch(lowerAllHands());

  const users = peersHandsUp.map(({ user }) => user);

  return (
    <div className="room-hands-dialog">
      <Button variant="contained" disableElevation sx={{ mb: 3 }} disabled={!peersHandsUp.length} onClick={onClick}>
        Опустить руки
      </Button>
      <UserList users={users} renderAction={renderContextMenu} />
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'roomHands',
  Title: 'Подняли руки',
  Footer: false,
  Body,
};
