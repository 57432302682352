import './index.less';
import { Link } from 'react-router-dom';

import logo from 'images/logo.png';
import icon from 'images/logo-icon.png';
import whiteIcon from 'images/logo-icon-white.png';

type Props = {
  withRedirect?: boolean;
  iconOnly?: boolean;
  style?: React.CSSProperties;
  isWhite?: boolean;
};

const Logo: React.FC<Props> = ({ withRedirect = true, style, iconOnly = false, isWhite = false }) => {
  const src = iconOnly ? (isWhite ? whiteIcon : icon) : logo;

  return (
    <section style={style} className="logo">
      {withRedirect ? (
        <Link className="logo" to="/">
          <img style={style} className="logo__img" src={src} alt="Логотип Body Coach" />
        </Link>
      ) : (
        <img style={style} className="logo__img" src={src} alt="Логотип Body Coach" />
      )}
    </section>
  );
};

export default Logo;
