import React, { createContext, useContext, useReducer } from "react";

type Track = MediaStreamTrack;

interface State {
  tracks: Map<string, Track>;
}

interface Action {
  type: "add" | "remove" | "update";
  payload: { id: string; track: Track };
}

const initialState: State = { tracks: new Map() };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "add":
      return { ...state, tracks: state.tracks.set(action.payload.id, action.payload.track) };
    case "remove":
      state.tracks.delete(action.payload.id);
      return { ...state };
    case "update":
      state.tracks.set(action.payload.id, action.payload.track);
      return { ...state };
    default:
      return state;
  }
}

const TracksContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {},
});

export function useTracks() {
  return useContext(TracksContext);
}

export function TracksProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <TracksContext.Provider value={{ state, dispatch }}>{children}</TracksContext.Provider>;
}
