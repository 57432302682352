import { MarkUnreadChatAlt, Construction } from '@mui/icons-material';
import { Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';

import EquipmentTab from './EquipmentTab';
import ChatTab from './ChatTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <Box sx={{ p: { xxxs: 3, sm: 6 } }} hidden={value !== index}>
      {value === index && children}
    </Box>
  );
}

const Body: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
          <Tab sx={{ minHeight: '50px' }} icon={<Construction />} iconPosition="start" label="Оборудование" />
          <Tab sx={{ minHeight: '50px' }} icon={<MarkUnreadChatAlt />} iconPosition="start" label="Чат" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <EquipmentTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChatTab />
      </TabPanel>
    </Box>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'roomSettings',
  Title: 'Настройки',
  Footer: false,
  Body,
};
