import { IUser } from 'types/user';
import { IStatistic, IWebinar, webinarState } from 'types/webinar';
import api from 'utils/api';
import { API } from 'utils/constants';

interface Response<T> {
  totalCount: number;
  webinars: T[];
}

type QueryArgument = {
  perPage: number;
  page: number;
  status: string;
  sort: string;
  search: string;
  owner: string;
};

export type WebinarDraftPatch = Omit<webinarState['draft'], 'cover' | 'speaker'> & {
  cover: string;
  speaker: string;
};

export const webinarApi = api.enhanceEndpoints({ addTagTypes: ['Webinar', 'Webinars'] }).injectEndpoints({
  endpoints: (build) => ({
    getWebinars: build.query<Response<IWebinar>, Partial<QueryArgument>>({
      query: (arg) => {
        const { owner, status, sort, page, perPage, search } = arg;
        return { url: API.WEBINARS, params: { status, sort, page, perPage, search, owner } };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.webinars.map(({ _id }) => ({ type: 'Webinars' as const, id: _id })),
              { type: 'Webinars', id: 'LIST' },
            ]
          : [{ type: 'Webinars', id: 'LIST' }],
    }),
    getWebinar: build.query<IWebinar, IWebinar['_id']>({
      query: (webinarId) => `${API.WEBINAR_LANDING}/${webinarId}`,
      providesTags: ['Webinar'],
    }),
    getBlackList: build.query<IUser[], IWebinar['_id']>({
      query: (webinarId) => `${API.WEBINARS}/${webinarId}/black-list`,
    }),
    getStatistic: build.query<IStatistic, IWebinar['_id']>({
      query: (webinarId) => `${API.WEBINARS}/statistics/${webinarId}`,
    }),
    createWebinar: build.mutation<IWebinar, WebinarDraftPatch>({
      query: (body) => ({ url: API.WEBINARS, method: 'POST', body }),
      invalidatesTags: [{ type: 'Webinars', id: 'LIST' }],
    }),
    editWebinar: build.mutation<IWebinar, { webinarId: IWebinar['_id']; patch: WebinarDraftPatch }>({
      query: ({ webinarId, patch }) => ({ url: `${API.WEBINARS}/${webinarId}`, method: 'PATCH', body: patch }),
      invalidatesTags: (result, error, { webinarId }) => [{ type: 'Webinars', id: webinarId }],
    }),
    deleteWebinar: build.mutation<void, string>({
      query: (webinarId) => ({ url: `${API.WEBINARS}/${webinarId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'Webinars', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBlackListQuery,
  useGetWebinarsQuery,
  useGetWebinarQuery,
  useCreateWebinarMutation,
  useGetStatisticQuery,
  useEditWebinarMutation,
  useDeleteWebinarMutation,
} = webinarApi;
