import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { deleteFile, fetchFiles, uploadFile, getFileStatistic } from 'actions/file';
import { fileState, FileType, IFile } from 'types/file';

const initialState: fileState = {
  files: [],
  currentType: FileType.video,
  isLoading: false,
  perPage: 9,
  page: 1,
  sort: 'createdAt',
  direct: true,
  search: '',
  // statistic
  totalCount: 0,
  totalSize: 0,
  filesByType: {
    video: { size: 0, count: 0 },
    audio: { size: 0, count: 0 },
    presentation: { size: 0, count: 0 },
    image: { size: 0, count: 0 },
  },
};

type deleteRes = {
  fileId: IFile['_id'];
  size: IFile['size'];
  type: IFile['type'];
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setCurrentType(state, { payload }: PayloadAction<keyof typeof FileType>) {
      state.files = state.currentType !== payload ? [] : state.files;
      state.currentType = payload;
      state.page = 1;
    },
    setSort(state, action) {
      state.sort = action.payload;
      state.page = 1;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    switchDirect(state) {
      state.direct = !state.direct;
    },
    setSearch(state, action) {
      state.files = [];
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFileStatistic.fulfilled, (state, { payload }: PayloadAction<fileState['filesByType']>) => {
        const values = [...Object.values(payload)];

        state.totalCount = values.reduce((acc, curr) => (acc += curr.count), 0);
        state.totalSize = values.reduce((acc, curr) => (acc += curr.size), 0);
        state.filesByType = payload;
      })
      .addCase(fetchFiles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFiles.fulfilled, (state, { payload }: PayloadAction<IFile[]>) => {
        state.isLoading = false;
        state.files = payload;
      })
      .addCase(fetchFiles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadFile.fulfilled, (state, { payload }: PayloadAction<IFile>) => {
        state.files.push(payload);

        state.totalCount = state.totalCount + 1;
        state.totalSize = state.totalSize + payload.size;

        state.filesByType[payload.type].count += 1;
        state.filesByType[payload.type].size += payload.size;

        state.page = Math.ceil(state.filesByType[payload.type].count / state.perPage);
      })
      .addCase(deleteFile.pending, () => {
        toast.loading('Удаляем файл...', {
          toastId: 'deleteFile',
        });
      })
      .addCase(deleteFile.fulfilled, (state, { payload }: PayloadAction<deleteRes>) => {
        if (payload) {
          state.files = state.files.filter((file) => file._id !== payload.fileId);
          state.totalCount = state.totalCount - 1;
          state.totalSize = state.totalSize - payload.size;

          state.filesByType[payload.type].count -= 1;
          state.filesByType[payload.type].size -= payload.size;

          state.page = Math.ceil(state.filesByType[payload.type].count / state.perPage);

          toast.update('deleteFile', {
            render: 'Файл удален',
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            closeOnClick: true,
            autoClose: 3000,
            closeButton: true,
          });
        }
      })
      .addCase(deleteFile.rejected, (state) => {
        toast.update('deleteFile', {
          render: 'Ошибка удаления файла',
          type: toast.TYPE.ERROR,
          isLoading: false,
          closeOnClick: true,
          autoClose: 3000,
          closeButton: true,
        });
      });
  },
});

export const { setCurrentType, switchDirect, setPage, setSearch, setSort } = fileSlice.actions;

export default fileSlice.reducer;
