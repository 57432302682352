import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState: any[] = [];

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openDialog: (state, { payload: { dialogs } }) => [...dialogs],
  },
});

export const { openDialog } = dialogsSlice.actions;

export default dialogsSlice.reducer;

const selectDialogs = (state: RootState) => state.dialogs;
export const getDialogs = createSelector(selectDialogs, (state) => state);
