import { UsersState } from 'store/reducers/users';
import { AdminDashboardUserEditDraft, IUser } from 'types/user';
import api from 'utils/api';
import { API } from 'utils/constants';
import { pickBy } from 'lodash';

export type UserDraftPatch = Partial<
  Omit<IUser, 'avatar'> & {
    avatar: string;
  }
>;

export type CheckAuthData = {
  user: IUser;
  accessToken: string;
};

interface Response<T> {
  totalCount: number;
  users: T[];
}

export const userApi = api.enhanceEndpoints({ addTagTypes: ['User', 'Users'] }).injectEndpoints({
  endpoints: (build) => ({
    // auth
    checkAuth: build.query<CheckAuthData, void>({
      query: () => API.REFRESH,
    }),
    signIn: build.mutation<CheckAuthData, Pick<IUser, 'email'> & { password: string }>({
      query: (body) => ({ url: API.SIGNIN, method: 'POST', body }),
      transformResponse: (response: CheckAuthData) => {
        localStorage.setItem('token', response.accessToken);
        return response;
      },
    }),
    signOut: build.mutation<{ token: string }, void>({
      query: () => ({ url: API.SIGNOUT, method: 'POST' }),
      transformResponse: (response: { token: string }) => {
        localStorage.removeItem('token');
        return response;
      },
    }),
    signUp: build.mutation<
      CheckAuthData,
      Pick<IUser, 'email' | 'name' | 'city' | 'country' | 'ip'> & { password: string }
    >({
      query: (body) => ({ url: API.SIGNUP, method: 'POST', body }),
      transformResponse: (response: CheckAuthData) => {
        localStorage.setItem('token', response.accessToken);
        return response;
      },
    }),
    signUpViewer: build.mutation<CheckAuthData, Pick<IUser, 'name' | 'city' | 'country' | 'ip'>>({
      query: (body) => ({ url: API.SIGNUP_VIEWER, method: 'POST', body }),
      transformResponse: (response: CheckAuthData) => {
        localStorage.setItem('token', response.accessToken);
        return response;
      },
    }),

    // edit
    editProfile: build.mutation<IUser, UserDraftPatch>({
      query: (body) => ({ url: API.ME, method: 'PATCH', body }),
      invalidatesTags: [{ type: 'User' }],
    }),
    confirmEmail: build.query<Pick<IUser, 'email'>, string>({
      query: (link) => `${API.EMAIL_CONFIRM}/${link}`,
    }),

    // pass recovery
    sendVerifyCode: build.mutation<boolean, Pick<IUser, 'email'>>({
      query: (body) => ({ url: API.PASS_RECOVERY, method: 'POST', body }),
    }),
    verifyCode: build.mutation<boolean, Pick<IUser, 'email'> & { code: string }>({
      query: (body) => ({ url: `${API.PASS_RECOVERY}/verify`, method: 'POST', body }),
    }),
    setNewPass: build.mutation<boolean, Pick<IUser, 'email'> & { code: string; password: string }>({
      query: (body) => ({ url: `${API.PASS_RECOVERY}/set-pass`, method: 'POST', body }),
    }),

    // --------------- GET ---------------

    getUsers: build.query<Response<IUser>, UsersState>({
      query: (params) => ({ url: API.USERS, params }),
      providesTags: (result) =>
        result
          ? [...result.users.map(({ _id }) => ({ type: 'Users' as const, id: _id })), { type: 'Users', id: 'LIST' }]
          : [{ type: 'Users', id: 'LIST' }],
    }),

    getUser: build.query<IUser, string>({
      query: (userId) => `${API.USERS}/${userId}`,
      providesTags: (result, error, userId) => [{ type: 'Users', id: userId }],
    }),

    // --------------- ADMIN ---------------

    createUser: build.mutation<void, Omit<AdminDashboardUserEditDraft, '_id' | 'avatar'> & { avatar: string | null }>({
      query: (body) => ({ url: API.USERS, method: 'POST', body: pickBy(body, (value) => value !== '') }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    deleteUsers: build.mutation<void, string[]>({
      query: (userIds) => ({ url: `${API.USERS}/delete`, method: 'POST', body: { userIds } }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    updateUser: build.mutation<void, Omit<AdminDashboardUserEditDraft, 'avatar'> & { avatar: string | null }>({
      query: (body) => ({ url: `${API.USERS}/${body._id}`, method: 'PATCH', body }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Users', id: _id }],
    }),
  }),
});

export const {
  // GET
  useGetUsersQuery,
  useGetUserQuery,

  // auth
  useCheckAuthQuery,
  useSignInMutation,
  useSignOutMutation,
  useSignUpMutation,
  useSignUpViewerMutation,

  // edit
  useEditProfileMutation,
  useConfirmEmailQuery,

  // pass recovery
  useSendVerifyCodeMutation,
  useVerifyCodeMutation,
  useSetNewPassMutation,

  // ADMIN
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUsersMutation,
} = userApi;
