import './index.less';

import CancelIcon from '@mui/icons-material/Cancel';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CriticalErrorState } from 'components/ErrorBoundary';

const CriticalError: React.FC<CriticalErrorState> = ({ error, time }) => {
  return (
    <div className="critical-error">
      <div className="critical-error__header">
        <div className="critical-error__icon-container">
          <CancelIcon sx={{ fontSize: 72 }} color="error" />
        </div>
        <Typography mb={3} variant="h4" component="h1" align="center">
          Ошибка
        </Typography>
        <Typography variant="body1" mb={6} align="center">
          В процессе работы приложения возникла ошибка. Пожалуйста, попробуйте обновить страницу (F5).
        </Typography>
      </div>
      <Stack width="100%" spacing={6} bgcolor="#fafafa" p={6}>
        <Stack mx="auto" alignItems="center" justifyContent="center" spacing={6}>
          <Stack width="100%" direction={{ xxxs: 'column', sm: 'row' }} spacing={6}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextsmsIcon />
              <Typography variant="button">Текст ошибки:</Typography>
            </Stack>
            {error && <div className="critical-error__data">{error.toString()}</div>}
          </Stack>
          <Stack width="100%" direction={{ xxxs: 'column', sm: 'row' }} spacing={6}>
            <Stack direction="row" spacing={2} alignItems="center">
              <AccessTimeIcon />
              <Typography variant="button">Дата и время:</Typography>
            </Stack>
            <div className="critical-error__data">{time}</div>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default CriticalError;
