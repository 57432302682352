import './index.less';
import cn from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import UploadFile from './UploadFile';
import { setIsVisible, closeUploaderWindow } from 'reducers/uploader';
import { useTypedDispatch, useTypedSelector } from 'store/index';

const Uploader = () => {
  const dispatch = useTypedDispatch();
  const { isCollapsed, uploadedFiles } = useTypedSelector((state) => state.uploader);

  const generalPercents = uploadedFiles.reduce((acc, { progress, error }) => {
    if (!error) return acc + progress;
    return acc;
  }, 0);

  const generalProgress = generalPercents / uploadedFiles.filter(({ error }) => !error).length / 100;

  const onClose = () => dispatch(closeUploaderWindow());
  const onChange = () => dispatch(setIsVisible(!isCollapsed));

  return (
    <div className="upload-wrapper">
      <div className="upload-inner-wrapper">
        <section className="uploader">
          <div className="uploader__header">
            <div className="uploader__load">
              <div style={{ transform: `scaleX(${generalProgress})` }} className="uploader__load-line" />
            </div>
            <span className="uploader__title">
              {generalProgress === 1 ? 'Все файлы загружены' : 'Загрузка материалов...'}
            </span>
            <div className="uploader__header-controlls">
              <span className="uploader__switch" onClick={onChange}>
                {isCollapsed ? 'Свернуть' : 'Развернуть'}
              </span>
              <CloseIcon onClick={onClose} className="uploader__close" />
            </div>
          </div>
          <div className={cn('uploader__content', { uploader__content_visible: isCollapsed })}>
            <div className="uploader__list">
              {uploadedFiles.map((file) => (
                <UploadFile key={file._id} {...file} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Uploader;
