import { useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useElementSize = (target: React.MutableRefObject<HTMLElement | null>): { width: number; height: number } => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    target.current && setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setSize({ width, height });
  });

  return size;
};

export default useElementSize;
