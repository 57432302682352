import "./index.less";
import { memo, useEffect, useRef } from "react";
import SimpleBarCore from "simplebar-core";
// ui
import SlideSelectorElement from "./element";
import Scrollbar from "shared/ui/Scrollbar";
// store
import { useTypedSelector } from "store/index";
import { getActiveTab } from "selectors";
// types
import { TabPresentation } from "types/room";

// ----------------------------------------------------------------------

const SlideSelector = memo(() => {
  const { isOpen } = useTypedSelector((state) => state.slideSelector);
  const activeTab = useTypedSelector(getActiveTab);

  const simpleBarRef = useRef<SimpleBarCore | null>(null);

  const { material: { previews = [], currentSlide = 0 } = {}, id } = (activeTab as TabPresentation) || {};

  useEffect(() => {
    if (!simpleBarRef.current) return;

    const simpleBarInstance = simpleBarRef.current.getScrollElement();
    const elementToScrollTo = simpleBarInstance!.querySelector(`[data-key="${currentSlide}"]`);

    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentSlide, simpleBarRef]);

  if (!isOpen || !activeTab || !activeTab.material) {
    return null;
  }

  return (
    <div className="silde-selector">
      <Scrollbar
        ref={simpleBarRef}
        sx={{
          height: 1,
          "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
        }}
      >
        {previews.map((src, index) => (
          <SlideSelectorElement key={index} {...{ src, index, currentSlide, tabId: id }} />
        ))}
      </Scrollbar>
    </div>
  );
});
export default SlideSelector;
