import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Role } from 'types/user';
import { checkAvailable } from 'store/actions/app';
import { useTypedDispatch, useTypedSelector } from 'store';

import { routeNames } from './routeNames';
import LoadingScreen from 'pages/Admin/shared/ui/LoadingScreen';

type Props = {
  children: React.ReactElement;
  redirectPath?: string;
  allowedRole: Role;
};

const Loadable = (Component: React.JSXElementConstructor<any>) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/admin')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const ProtectedRoute: React.FC<Props> = ({ allowedRole, children, redirectPath = '/' }) => {
  const dispatch = useTypedDispatch();
  const { isAuth, isLoaded } = useTypedSelector((state) => state.user);
  const isAvailable = dispatch(checkAvailable(allowedRole));

  const { pathname } = useLocation();

  if (!isLoaded) {
    return <LoadingScreen isDashboard={pathname.includes('/admin')} />;
  }

  if (!isAvailable || !isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* webinar */}
      <Route path="/webinars" element={<WebinarSchedule />} />
      <Route path="/webinars/landing/:webinarId" element={<WebinarLanding />} />
      <Route path="/webinar/:webinarId" element={<Webinar />} />

      {/* user */}
      {userRoutes.map(({ allowedRole, path, Component }) => (
        <Route
          key={path}
          path={path}
          index
          element={
            <ProtectedRoute allowedRole={allowedRole}>
              <Component />
            </ProtectedRoute>
          }
        />
      ))}

      {/* auth */}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/email-confirm/:link" element={<EmailConfirm />} />

      {/* admin */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute allowedRole={Role.ADMIN}>
            <AdminPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="/admin/user/list" replace />} />
        <Route path="user/list" element={<UserList />} />
        <Route path="user/profile" element={<UserProfile />} />
        <Route path="user/new" element={<UserCreate />} />
        <Route path="user/:userId/edit" element={<UserEdit />} />

        <Route path="webinar/list" element={<WebinarList />} />
        <Route path="webinar/new" element={<WebinarCreate />} />
        <Route path="webinar/:webinarId/edit" element={<WebinarCreate />} />

        <Route path="records" element={<Records />} />
      </Route>
      <Route path="*" element={<NonExistent />} />
    </Routes>
  );
};

export default Router;

// ----------------------------------------------------------------------

// MAIN
const Home = Loadable(lazy(() => import('pages/Home')));
const NonExistent = Loadable(lazy(() => import('pages/NonExistent')));

// WEBINAR
const WebinarSchedule = Loadable(lazy(() => import('pages/WebinarSchedule')));
const WebinarLanding = Loadable(lazy(() => import('pages/WebinarLanding')));
const Webinar = Loadable(lazy(() => import('pages/Webinar')));

// USER
const UserFiles = Loadable(lazy(() => import('pages/UserFiles')));
const Profile = Loadable(lazy(() => import('pages/Profile')));
const CabinetWebinars = Loadable(lazy(() => import('pages/CabinetWebinars')));
const WebinarCreation = Loadable(lazy(() => import('pages/WebinarCreation')));
const WebinarEdit = Loadable(lazy(() => import('pages/WebinarEdit')));
const WebinarStatistics = Loadable(lazy(() => import('pages/WebinarStatistics')));
const RecordsPage = Loadable(lazy(() => import('pages/Records')));

// AUTHENTICATION
const SignIn = Loadable(lazy(() => import('pages/SignIn')));
const SignUp = Loadable(lazy(() => import('pages/SignUp')));
const PasswordRecovery = Loadable(lazy(() => import('pages/PasswordRecovery')));
const EmailConfirm = Loadable(lazy(() => import('pages/EmailConfirm')));

// ADMIN DASHBOARD
const AdminPage = Loadable(lazy(() => import('pages/Admin')));

const UserList = Loadable(lazy(() => import('pages/Admin/pages/UserList')));
const UserCreate = Loadable(lazy(() => import('pages/Admin/pages/UserCreate')));
const UserEdit = Loadable(lazy(() => import('pages/Admin/pages/UserEdit')));
const UserProfile = Loadable(lazy(() => import('pages/Admin/pages/UserProfile')));

const WebinarList = Loadable(lazy(() => import('pages/Admin/pages/WebinarList')));
const WebinarCreate = Loadable(lazy(() => import('pages/Admin/pages/WebinarCreate')));

const Records = Loadable(lazy(() => import('pages/Admin/pages/Records')));

// ----------------------------------------------------------------------

type PageRoute = {
  path: string;
  Component: (props: any) => JSX.Element;
  allowedRole: Role;
};

const userRoutes: PageRoute[] = [
  {
    path: routeNames.PROFILE_PAGE,
    Component: Profile,
    allowedRole: Role.MEMBER,
  },
  {
    path: routeNames.USER_FILES_PAGE,
    Component: UserFiles,
    allowedRole: Role.MODERATOR,
  },
  {
    path: routeNames.USER_WEBINARS_PAGE,
    Component: CabinetWebinars,
    allowedRole: Role.MODERATOR,
  },
  {
    path: routeNames.WEBINAR_CREATION_PAGE,
    Component: WebinarCreation,
    allowedRole: Role.MODERATOR,
  },
  {
    path: routeNames.WEBINAR_EDIT_PAGE,
    Component: WebinarEdit,
    allowedRole: Role.MODERATOR,
  },
  {
    path: routeNames.WEBINAR_STATISTICS_PAGE,
    Component: WebinarStatistics,
    allowedRole: Role.MODERATOR,
  },
  {
    path: routeNames.RECORDS_PAGE,
    Component: RecordsPage,
    allowedRole: Role.MODERATOR,
  },
];
