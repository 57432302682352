import "./index.less";
import { memo, useState } from "react";
import ReactPlayer from "react-player";
import CircularProgress from "@mui/material/CircularProgress";

import Result from "components/Result";
import Panel from "./Panel";
import { sourceWithBaseUrl } from "utils/index";

const Body = memo(({ close, source }) => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="dialog-video-view">
      {isError || !source ? (
        <Result
          type="error"
          color="error"
          maxWidth={540}
          subTitle="Мы не смогли загрузить Ваше видео. Возможно, оно было удалено или к нему был изменен путь"
        />
      ) : (
        <>
          {!isLoaded && (
            <div className="dialog-video-view__loader">
              <CircularProgress />
            </div>
          )}
          <Panel {...{ stopPropagation, close }} />
          <div onClick={stopPropagation} className="dialog-video-view__content">
            <ReactPlayer
              style={{ opacity: isLoaded ? 1 : 0, maxHeight: "100vh" }}
              onError={() => setIsError(true)}
              onReady={() => setIsLoaded(true)}
              controls
              width="100%"
              height="100%"
              className="react-player"
              url={sourceWithBaseUrl(source)}
            />
          </div>
        </>
      )}
    </div>
  );
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fullscreen: true,
  name: "videoView",
  Body,
};
