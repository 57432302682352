import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";

import { useTypedDispatch, useTypedSelector } from "store/index";
import { selectAudioDevice, selectVideoDevice } from "reducers/devices";
import React from "react";

type Props = {
  isMicroOnly?: boolean;
};

const DeviceSelector: React.FC<Props> = ({ isMicroOnly = false }) => {
  const dispatch = useTypedDispatch();
  const { selectedAudioDevice, selectedVideoDevice, audioDevices, videoDevices } = useTypedSelector(
    (state) => state.devices
  );
  const selectAudio = (e: SelectChangeEvent) =>
    dispatch(selectAudioDevice(audioDevices.find((device) => device.deviceId === e.target.value)));
  const selectVideo = (e: SelectChangeEvent) =>
    dispatch(selectVideoDevice(videoDevices.find((device) => device.deviceId === e.target.value)));

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={6}>
      <FormControl
        sx={{ minWidth: 120, maxWidth: 420 }}
        fullWidth
        size="small"
        error={!selectedAudioDevice}
        disabled={!selectedAudioDevice}
      >
        <InputLabel id="audio-select-label">Микрофон</InputLabel>
        <Select
          labelId="audio-select-label"
          id="audio-select"
          value={selectedAudioDevice?.deviceId || ""}
          label="Микрофон"
          onChange={selectAudio}
        >
          {audioDevices.map(({ deviceId, label }, key: number) => (
            <MenuItem key={key} value={deviceId}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {!audioDevices.length && <FormHelperText>Оборудование не найдено</FormHelperText>}
      </FormControl>
      <FormControl
        sx={{ minWidth: 120, maxWidth: 420 }}
        fullWidth
        size="small"
        error={!selectedVideoDevice}
        disabled={!selectedVideoDevice || isMicroOnly}
      >
        <InputLabel id="audio-select-label">Камера</InputLabel>
        <Select
          labelId="audio-select-label"
          id="audio-select"
          value={selectedVideoDevice?.deviceId || ""}
          label="Камера"
          onChange={selectVideo}
        >
          {videoDevices.map(({ deviceId, label }, key: number) => (
            <MenuItem key={key} value={deviceId}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {!videoDevices.length && <FormHelperText>Оборудование не найдено</FormHelperText>}
      </FormControl>
    </Stack>
  );
};

export default DeviceSelector;
