import Konva from 'konva';
import { createContext } from 'react';

export interface IRoomContext {
  contextState: {
    whiteboardSnapshot: HTMLImageElement | null;
    imageRef: React.RefObject<HTMLImageElement> | null;
    recordLayerRef: React.RefObject<Konva.Layer> | null;
    audioContext: AudioContext | null;
    destination: MediaStreamAudioDestinationNode | null;
  };
  setContextState: React.Dispatch<React.SetStateAction<IRoomContext['contextState']>>;
}

const RoomContext = createContext<IRoomContext | null>(null);

export default RoomContext;
