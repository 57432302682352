import React from 'react';

import { Box, Zoom, Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const BackToTop = () => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100 });

  const scrollToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Zoom in={trigger}>
      <Box role="presentation" sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 100500 }}>
        <Fab onClick={scrollToTop} color="primary" size="small" aria-label="scroll back to top">
          <ArrowUpwardIcon />
        </Fab>
      </Box>
    </Zoom>
  );
};

export default BackToTop;
